import React, { useCallback, useEffect, useRef } from 'react'
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { useNavigate, useParams } from 'react-router';
import { DeleteAttendeeRequest, RaceAttendeeDTO, UpdateRaceAttendeesBeforeStartRequest } from '../Api/ApiServerDC';
import { Alert, Box, Button, Snackbar, TextField, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import DeleteDialog from '../Shared/DeleteDialog';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { csCZ } from '@mui/x-data-grid/locales';

declare global {
    interface Navigator {
      msSaveOrOpenBlob?: (blob: Blob, defaultName?: string) => boolean;
    }
  }

export default function RaceAttendees() {
    const [attendees, setAttendees] = React.useState<RaceAttendeeDTO[]>([]);
    const [deleteErrorOpen, setDeleteErrorOpen] = React.useState(false);
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = () => {
        if (params && params["id"])
            new ApiBaseClient().GetAuthedClient().raceAttendees(Number(params["id"])).then((res) => {
                if (res && res.raceAttendees)
                    setAttendees(res.raceAttendees);
            });
    };
    
    const tableRef = useRef(null);
    const [deleting, setDeleting] = React.useState<RaceAttendeeDTO | undefined>();

   
    const exportTableToExcel = () => {
        if (params && params["id"])
            navigate('/attendees_public/' + params["id"]);
    };

    const columns: GridColDef[] = [
        {
            field: 'NULL',
            headerName: '...',
            flex: 1,
            minWidth: 100,
            width: 100,
            renderCell: (params) => {
                return <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: '5px'}}>
                    <Edit onClick={() => navigate('/raceattendee/' + params.row.id)} style={{ cursor: 'pointer' }} />
                    <Delete onClick={() => setDeleting(params.row)} style={{ cursor: 'pointer' }} />
                </div>
            }
        },
        {
            field: 'startNumber',
            headerName: 'START.ČÍSLO',
            flex: 1,
            minWidth: 100,
            renderCell: useCallback((params) => {
                return  <TextField
                    label='číslo'
                    autoSave='10'
                    size='small'
                    sx={{ marginTop: '4px' }}
                    error={attendees.some((p) => (p.startNumber === params.row.startNumber && p.id !== params.row.id))}
                    type='number' value={params.row.startNumber} onChange={(e) => {
                        new ApiBaseClient().GetAuthedClient().updateRaceAttendeesBeforeStart(new UpdateRaceAttendeesBeforeStartRequest({
                            attendeeId: params.row.id,
                            startNumber: e.target && e.target.value? Number(e.target.value) : undefined,
                            signatureBase64: undefined
                        }))/* .then(() => reloadData()) */;
                        setAttendees(prev => {
                            return prev.map(p => {
                                if (p.id === params.row.id)
                                    return {...params.row, startNumber: e.target && e.target.value ? Number(e.target.value) : undefined}
                                else 
                                    return p;
                            });
                        });
                    }}>
                </TextField>
            }, [attendees])
        },
        {
            field: 'firstName',
            headerName: 'JMÉNO',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'lastName',
            headerName: 'PŘÍJMENÍ',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'proCategory',
            headerName: 'KATEGORIE',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => {
                const isPro = params.row.proCategory;
                const isStreet = params.row.streetCategory;
                if (isPro && isStreet) {
                    return 'Street & Pro';
                } else if (isPro) {
                    return 'Pro';
                } else if (isStreet) {
                    return 'Street';
                } else {
                    return '';
                }
            },
        },
        {
            field: 'email',
            headerName: 'EMAIL',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'phone',
            headerName: 'TELEFON',
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'car',
            headerName: 'AUTO',
            flex: 1,
            minWidth: 100,
        }
    ];

    return (
        <div style={{ /* display: 'flex', flexDirection: 'column', */ margin: '0px -140px' }}>
            <div><Button onClick={() => { navigate('/eventsadmin') }}>Zpět </Button></div>
            <div style={{textAlign: 'center'}}>
                <Typography variant='h4' color='#D82C90'>SEZNAM ÚČASTNÍKŮ</Typography></div>
                <div style={{ display: 'flex'}}>
                    <Button sx={{ margin: '5px'}} size='small' variant='contained' onClick={() => navigate('/raceattendee/0/' + Number(params["id"]))}>Přidat závodníka</Button>                 
                    <Button sx={{ margin: '5px'}} size='small' variant='contained' onClick={exportTableToExcel}>Veřejný seznam</Button>
                </div>
                <Box >
                    <DataGrid
                        columns={columns}
                        rows={attendees}
                        //rowHeight={45}
                        editMode="row"
                        pagination={false as true}
                        pageSizeOptions={[100]}
                        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                        sx={{
                            '& .MuiDataGrid-row': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                            },
                            '& .MuiDataGrid-cell': {
                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                            },
                        }}
                    />   
                </Box>             
            <DeleteDialog onDeleteAgree={() => {
                if (deleting){
                    new ApiBaseClient().GetAuthedClient().deleteAttendee(new DeleteAttendeeRequest({ raceAttendeeId: deleting.id })).then((res) => { setDeleting(undefined); if (res.ok) refreshData(); else setDeleteErrorOpen(true);});
                }
            }} onClose={() => setDeleting(undefined)} open={deleting !== undefined} />

            
            <Snackbar open={deleteErrorOpen} autoHideDuration={3000} onClose={() => setDeleteErrorOpen(false)}>

                <Alert onClose={() => setDeleteErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
                    Nelze smazat - závodník již závodil
                </Alert>

            </Snackbar>
        </div>
    )
}
