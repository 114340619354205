import React from 'react'
import { Dialog, DialogContent, Typography } from '@mui/material';

export default function UnsuccessPayment() {
  return (

    <div style={{ display: 'flex', justifyContent: 'center', margin: '0px -140px' }}>
      <div style={{ border: '1px solid #D82C90', borderRadius: '8px', padding: '40px', maxWidth: '600px' }}>
        <div>
          <Typography variant='h6' textAlign='center' color='red'>
            Platba se nezdařila, pokud Vám byli peníze strženy, kontaktujte nás na tel. 770 180 118 kde Váš problém budeme aktivně řešit.

          </Typography>
        </div>

      </div>
    </div>

  )
}
