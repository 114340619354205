import { TextField, Autocomplete, Button, FormControlLabel, Checkbox, Typography, useMediaQuery, useTheme, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RaceAttendeeDTO, RaceWhisperDTO, SaveRaceAttendeeDetailRequest, SignUpToRaceRequest } from '../Api/ApiServerDC';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { useNavigate, useParams } from 'react-router';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SuccessRegistrationDialogNoPay from '../PublicScenes/SuccessRegistrationDialogNoPay';

export default function RaceAttendeeDetail() {
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        new ApiBaseClient().GetAuthedClient().getAllAvailableRacesAdmin().then((e) => {
            if (e.races)
                setRaces(e.races);


        });
    }, []);

    const theme = useTheme();
    const biggerThan700 = useMediaQuery(theme.breakpoints.up(700));
    const [races, setRaces] = React.useState<RaceWhisperDTO[]>([]);
    const [selectedRace, setSelectedRace] = React.useState<RaceWhisperDTO | null>(null);

    const [raceAttendee, setRaceAttendee] = React.useState<RaceAttendeeDTO>();
    
    const [successOpen, setSuccessOpen] = useState(false);
    useEffect(() => {
        if (races.length > 0)
            if (params && params.id && params.id !== '0') {
                new ApiBaseClient().GetAuthedClient().getRaceAttendeeDetail(Number(params.id)).then((res) => {
                    setSelectedRace(races.find(x => x.id === res.raceAttendee.raceId)!);
                    setRaceAttendee(res.raceAttendee);
                });
            }
            else {
                setSelectedRace(races.find(x => x.id === Number(params.race))!);
                setRaceAttendee(new RaceAttendeeDTO({ raceId: Number(params.race), isPaid: true}));
            }
    }, [races, params]);

    if (raceAttendee)
        return (
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: biggerThan700 ? '' : '-160px', marginRight: biggerThan700 ? '' : '-160px' }}>
                <div><Button onClick={() => {selectedRace ? navigate('/attendees/' + selectedRace.id) : navigate('/eventsadmin') }}>Zpět </Button></div>
                <Typography variant='h3' color='#D82C90' textAlign='center'>REGISTRACE</Typography>
                <TextField sx={{ margin: '12px 0px' }} label='Jméno' value={raceAttendee.firstName ?? ""} onChange={(e) => { setRaceAttendee(new RaceAttendeeDTO({...raceAttendee, firstName: e.currentTarget.value })) }} />
                <TextField sx={{ margin: '12px 0px' }} label='Přijmení' value={raceAttendee.lastName ?? ""} onChange={(e) => { setRaceAttendee(new RaceAttendeeDTO({...raceAttendee, lastName: e.currentTarget.value }))}} />

                <TextField  sx={{ margin: '12px 0px' }} label='Adresa' value={raceAttendee.address ?? ""} onChange={(e) => { setRaceAttendee(new RaceAttendeeDTO({...raceAttendee, address: e.currentTarget.value })) }} />
                {/* <TextField error={!isValidDate(bornDate)} sx={{ margin: '12px 0px' }} label='Datum narození' value={bornDate} onChange={(e) => { setBornDate(e.currentTarget.value) }} /> */}
                <LocalizationProvider adapterLocale='cs' dateAdapter={AdapterDayjs} >
                    <DatePicker sx={{ margin: '12px 0px' }} format="DD.MM.YYYY"
                            label="Datum narození" value={dayjs(raceAttendee.bornDate)} onChange={(value) => { setRaceAttendee(new RaceAttendeeDTO({...raceAttendee, bornDate: value.toDate() }))}} />
                </LocalizationProvider>

                <TextField sx={{ margin: '12px 0px' }} label='Email' value={raceAttendee.email ?? ""} onChange={(e) => { setRaceAttendee(new RaceAttendeeDTO({...raceAttendee, email: e.currentTarget.value }))}} />
                <TextField sx={{ margin: '12px 0px' }} label='Telefon' value={raceAttendee.phone ?? ""} onChange={(e) => { setRaceAttendee(new RaceAttendeeDTO({...raceAttendee, phone: e.currentTarget.value }))}} />
                <Autocomplete
                    getOptionLabel={o => o !== null ? o!.name! : ""}
                    options={races}
                    value={selectedRace}
                    sx={{ margin: '12px 0px' }}
                    onChange={(e, v) => { setSelectedRace(v) }}
                    renderInput={(e) => { return <TextField {...e} label={'Závod'} /> }}></Autocomplete>
                {(selectedRace && !selectedRace.isMeeting) && <div style={{display:'flex'}}>
                    <FormControlLabel
                        control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={raceAttendee.proCategory ?? false}
                            onChange={(e, checked) => { setRaceAttendee(new RaceAttendeeDTO({...raceAttendee, proCategory: checked }))}} />}
                        label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>PRO</Typography>}
                        labelPlacement="end"
                    />
                    <FormControlLabel

                        control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={raceAttendee.streetCategory ?? false}
                            onChange={(e, checked) => { setRaceAttendee(new RaceAttendeeDTO({...raceAttendee, streetCategory: checked })) }} />}
                        label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>STREET</Typography>}
                        labelPlacement="end"
                    />
                </div>}
                
                {/* <FormControlLabel

                        control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={raceAttendee.isPaid ?? false}
                            onChange={(e, checked) => { setRaceAttendee(new RaceAttendeeDTO({...raceAttendee, isPaid: checked })) }} />}
                        label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>ZAPLACENO</Typography>}
                        labelPlacement="end"
                    /> */}
                <TextField sx={{ margin: '12px 0px' }} label='Auto' value={raceAttendee.car ?? ""} onChange={(e) => { setRaceAttendee(new RaceAttendeeDTO({...raceAttendee, car: e.currentTarget.value }))}} />
                <Button variant='contained' disabled={!raceAttendee.firstName || !raceAttendee.lastName || !raceAttendee.email || !raceAttendee.phone || !selectedRace || !raceAttendee.address || raceAttendee.bornDate === null } onClick={() => {
                    if (selectedRace === null) return;
                    new ApiBaseClient().GetAuthedClient().saveRaceAttendeeDetail(new SaveRaceAttendeeDetailRequest({
                         raceAttendee: new RaceAttendeeDTO({...raceAttendee, raceId: selectedRace.id})
                    })).then(() => {
                        setSuccessOpen(true);
                        navigate('/attendees/' + selectedRace.id);
                    })
                }} sx={{ /* height: '80px', */ marginTop: '16px' }}><Typography variant='h4'>{'Uložit'}</Typography></Button>

                <Snackbar
                    open={successOpen}
                    autoHideDuration={3000}
                    onClose={() => setSuccessOpen(false)}
                    message="Uloženo"
                />
            </div>
        )
        else return <div>loading ...</div>;
}
