import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

export interface DeleteDialogProps {
    onDeleteAgree: (event: any) => void;
    onClose: (event: any) => void;
    open: boolean;
    data?: any;
}
export default function DeleteDialog(props: DeleteDialogProps, ref: any) {
    const { onDeleteAgree, onClose, open, data } = props;
    const handleClose = (e: any) => {
        onClose(e);
    };

    return (
        <Dialog
            open={open}
            onClose={() => { handleClose(data) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Opravdu smazat?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {"Opravdu smazat?"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { handleClose(data) }}>{'NE'}</Button>
                <Button onClick={() => { onDeleteAgree(data) }} autoFocus>
                    {'ANO'}
                </Button>
            </DialogActions>
        </Dialog>);
}