import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BracketMatchDTO, BroadcastCurrentBracketPositionRequest, ChangeRaceStateRequest, RaceState, UndoBracketMatchRequest, UpdateRaceBracketRequest } from "../Api/ApiServerDC";
import { ApiBaseClient } from "../Api/ApiBaseClient";
import { useNavigate, useParams } from "react-router";
import { Button, CircularProgress, IconButton, Typography } from "@mui/material";
import SignalRConnector from "./SinglaRConnector";
import { CarCrashOutlined, CheckOutlined, Close, Maximize, Visibility } from "@mui/icons-material";
import { SingleEliminationBracket, SVGViewer, createTheme, Match } from "../TournamentBracket";
import useWindowSize from "../TournamentBracket/src/hooks/use-window-size";
import { useMediaPredicate } from 'react-media-hook';
import UndoBracketDialog from "../Shared/UndoBracketDialog";
import { useLongPress } from 'use-long-press';
import generatePDF from 'react-to-pdf';

interface Participant {
    id: string;
    resultText: string;
    isWinner: boolean;
    status: string;
    name: string;
    picture: string;
}

interface MatchType {
    id: number;
    nextMatchId: number | null;
    tournamentRoundText?: string;
    startTime: string;
    state: string;
    participants: Participant[];
    isThirdPlaceMatch?: boolean;
}
interface ActiveBracketType {
    round: number;
    position: number;
}
function convertToMatchType(dto: BracketMatchDTO): MatchType {
    return {
        id: dto.id ?? 0, // If id is undefined, use a default value (e.g., 0)
        nextMatchId: dto.nextMatchId ?? null, // If nextMatchId is undefined, use null
        tournamentRoundText: dto.tournamentRoundText, // No need for conversion
        startTime: dto.startTime instanceof Date ? dto.startTime.toISOString() : dto.startTime ?? "", // Convert Date to string, handle null/undefined
        state: dto.state ?? "", // If state is undefined, use an empty string
        isThirdPlaceMatch: dto.isThirdPlaceMatch ?? false, // If isThirdPlaceMatch is undefined, use false
        participants: dto.participants?.map(participant => ({
            id: participant.id!.toString(), // Convert number to string
            resultText: participant.resultText ?? "", // If resultText is undefined, use an empty string
            isWinner: participant.isWinner ?? false, // If isWinner is undefined, use false
            status: participant.status ?? "", // If status is undefined, use an empty string
            name: participant.name ?? "", // If name is undefined, use an empty string
            picture: participant.picture ?? "" // If picture is undefined, use an empty string
        })) ?? [] // If participants is undefined, use an empty array
    };
}
interface Props {
}

export function SingleEliminationProjector(props: Props) {
    const params = useParams();
    const [width, height] = useWindowSize();

    const finalWidth = Math.max(width, 200);
    const finalHeight = Math.max(height, 200);

    useEffect(() => {
    }, [width, height]);

    useEffect(() => {
        refresh({ round: 0, position: 1 });
    }, []);

    const [isPro, setIsPro] = useState(true);
    const [undoMatchId, setUndoMatchId] = useState<number | undefined>();
    const [serverMatches, setServerMatches] = useState<MatchType[]>([]);
    const [activeBracket, setActiveBracket] = useState<ActiveBracketType>();
    const [loading, setLoading] = useState<boolean>(true);

    const [matches, setMatches] = useState<MatchType[]>([]);
    const [position, setPosition] = useState<ActiveBracketType>({ round: 0, position: 1 })

    const navigate = useNavigate();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    const signalUpdateState = useCallback((params: any) => {
        if ((params as RaceState) === RaceState.ProFinal) {
            setIsPro(true);
        }
        else if ((params as RaceState) === RaceState.StreetFinal) {
            setIsPro(false);
        }

    }, [isPro]);
    const refresh = useCallback((pos: ActiveBracketType | undefined) => {
        new ApiBaseClient().GetAuthedClient().getBracketForCurrentRace(Number(params['raceID']), isPro).then((races) => {
            if (races.matches)
                setMatches(races.matches.map((match) => convertToMatchType(match)));
            else
                setMatches([]);

            setPosition(pos ? pos : { round: 0, position: 1 });

            setLoading(false);
        }).catch(() => setLoading(false));
    }, [isPro, params]);

    const changeState = useCallback(() => {        
        setIsPro(!isPro);
    }, [isPro, params]);

    const signalUpdateBracket = useCallback((params: any) => {
        if ( params.isPro === isPro){
            refresh({ round: params.round, position: params.row });

        }
    }, [params, isPro]);

    useEffect(() => {

        refresh(activeBracket ?? { round: 0, position: 1 });
    }, [isPro, refresh]);
    useEffect(() => {

        // ě();
        let connection = SignalRConnector([{
            hubName: "BracketUpdateState", callback: (p) => {
                signalUpdateBracket(p);
            }
        }, { hubName: "ChangeRaceState", callback: (p) => signalUpdateState(p) }]);
        return () => {
            connection.stop();
        };
    }, [signalUpdateState, signalUpdateBracket]);

    const targetRef = useRef<HTMLDivElement>(null);
    
    if (loading)
        return <CircularProgress />
    else if (matches.length > 0)
        return <div style={{ marginLeft: '-160px', marginTop: '-40px', marginRight: '-160px' }}>
            <div ref={targetRef}><SingleEliminationBracket
                theme={GlootTheme}
                matches={matches}
                currentRound={position ? position.round + "" : "0"}
                currentRow={position ? position.position + "" : "1"}
                //   matchComponent={Match}
                svgWrapper={({ children, ...props }) => (
                    <SVGViewer
                        width={finalWidth} height={finalHeight}
                        // tady ta height má vliv na dragovatelnost (když je větší než celkové výška vygenerovaného bracketu, tak nejde drag))
                        background="white"
                        SVGBackground="#FFFFFF"
                        //startAt={positionLocal}
                        {...props}

                    >
                        {children}
                    </SVGViewer>
                )}
                matchComponent={({
                    match, onMatchClick, onPartyClick, onMouseEnter, onMouseLeave, topParty, bottomParty, topWon, bottomWon, topHovered, bottomHovered, topText, bottomText, connectorColor, computedStyles, teamNameFallback, resultFallback,
                }) => (
                    // <div {...bind()}>
                    <a
                        id={match.id!.toString()}
                        onClick={(r) => {
                            console.log(match);
                            // onMatchClick({ match: match, topWon: match.participants[0].isWinner === true, bottomWon: match.participants[1].isWinner === true, event: r });
                        } }
                        style={{
                            // position: 'relative', // Add this line
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            color: match.state === "PLAYED" ? '#000' : (match.state === "RUNNING" ? 'white' : '#000'),

                            minHeight: 'calc(100% - 40px)',
                            border: '2px solid #D82C90',
                            padding: '8px 24px 8px 8px',
                            background: match.state === "PLAYED" ? '#D82C9020' : (match.state === "RUNNING" ? '#D82C90' : 'white')
                        }}
                    >
                        <div style={{ display: '' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '0px', minWidth: '224px' }}>
                                <div style={{ paddingRight: '12px', alignSelf: 'center' }}><Typography variant="h6" color={match.state === "PLAYED" ? '#D82C90' : (match.state === "RUNNING" ? 'white' : '#D82C90')} fontSize='20px'>{match.tournamentRoundText}</Typography></div>
                                <div style={{ flexGrow: 1 }}>
                                    <div
                                        onMouseEnter={() => onMouseEnter(topParty.id)}
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >

                                        <div>
                                            <div><Typography variant="body1">{topParty.name || teamNameFallback}</Typography></div>
                                            <div><Typography variant="h6" fontSize='14px'>{topParty.resultText ?? resultFallback(topParty)} {/* - {topParty.resultText ?? resultFallback(topParty) */}</Typography></div>
                                        </div>
                                        <div>{topParty.isWinner === true && <CheckOutlined sx={{ color: '#D82C90', paddingTop: '4px' }} />}</div>
                                    </div>
                                    <div
                                        style={{ height: '2px', width: '100%', background: match.state === "PLAYED" ? '#D82C90' : (match.state === "RUNNING" ? 'white' : '#D82C90') }} />
                                    <div
                                        onMouseEnter={() => onMouseEnter(bottomParty.id)}
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <div>
                                            <div><Typography variant="body1">{bottomParty.name || teamNameFallback}</Typography></div>
                                            <div><Typography variant="h6" fontSize='14px'>{bottomParty.resultText ?? resultFallback(topParty)} {/* - {bottomParty.resultText ?? resultFallback(topParty) */}</Typography></div>
                                        </div>
                                        <div>{bottomParty.isWinner === true && <CheckOutlined sx={{ color: '#D82C90', paddingTop: '8px' }} />}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                    // </div>
                )}
                options={{
                    style: {
                        roundHeader: {
                            roundTextGenerator: (currentRoundNumber, roundsTotalNumber) => {
                                if (currentRoundNumber === roundsTotalNumber) {
                                    return 'FINÁLE';
                                }
                                if (currentRoundNumber === roundsTotalNumber - 1) {
                                    return 'SEMIFINÁLE';
                                }
                                else if (roundsTotalNumber > 4 && currentRoundNumber === roundsTotalNumber - 2) {
                                    return 'ČTVRTFINÁLE';
                                }

                                else
                                    return `${currentRoundNumber}. KOLO`;

                            }
                        },
                        boxHeight: 134
                    }
                }}
                onMatchClick={(match) => console.log(match)}
                onPartyClick={(match, partyWon) => { console.log(match, partyWon); } } startE={0} startF={0} usePosition      /></div>
            <div style={{ position: 'fixed', bottom: biggerThan700 ? '8%' : '80px', top: biggerThan700 ? '92%' : '90%', left: biggerThan700 ? '38%' : '16px' }}>
                <Button sx={{ marginRight: biggerThan700 ? '20px' : '4px' }}
                    variant="contained"
                    onClick={changeState}
                >{biggerThan700 ? (isPro ? 'STREET' : 'PRO') : (isPro ? 'ST.' : 'PR.')}</Button>                
            </div>
        </div>
    else return <div>
        <div>Battle ještě nezačal, přijďte později ...</div>
        <div style={{ position: 'fixed', bottom: biggerThan700 ? '8%' : '80px', top: biggerThan700 ? '92%' : '90%', left: biggerThan700 ? '38%' : '16px' }}>
                <Button sx={{ marginRight: biggerThan700 ? '20px' : '4px' }}
                    variant="contained"
                    onClick={changeState}
                >{biggerThan700 ? (isPro ? 'STREET' : 'PRO') : (isPro ? 'ST.' : 'PR.')}</Button>
            </div>
    </div>;
};

const GlootTheme = createTheme({
    textColor: { main: '#000000', highlighted: '#07090D', dark: '#3E414D' },
    matchBackground: { wonColor: '#daebf9', lostColor: '#96c6da' },
    score: {
        background: { wonColor: '#87b2c4', lostColor: '#87b2c4' },
        text: { highlightedWonColor: '#7BF59D', highlightedLostColor: '#FB7E94' },
    },
    border: {
        color: '#CED1F2',
        highlightedColor: '#da96c6',
    },
    roundHeader: { backgroundColor: '#da96c6', fontColor: '#000' },
    connectorColor: '#CED1F2',
    connectorColorHighlight: '#da96c6',
    svgBackground: '#FAFAFA',
    roundHeaders: { background: '#D82C90' }

});
