import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router'
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { RaceAttendeeStartRaceDTO, SetRaceAttendeeTechnicalOkRequest, SetRaceAttendeeTechnicalOkResponse, UpdateRaceAttendeesBeforeStartRequest } from '../Api/ApiServerDC';
import { Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import DisclaimerDialog from './DisclaimerDialog';
import { Check } from '@mui/icons-material';

export default function StartCurrentRace() {
    const [attendees, setAttendees] = useState<Partial<RaceAttendeeStartRaceDTO>[]>([]);
    const [raceId, setRaceId] = useState<number>();
    const [disclaimerOpen, setDisclaimerOpen] = useState<boolean>(false);
    const [raceInfo, setRaceInfo] = useState<{name: string, dateRange: string, place: string}>();
    const [selectedAttendee, setSelectedAttendee] = useState<RaceAttendeeStartRaceDTO>();
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        reloadData();
    }, []);

    useEffect(() => {
        reloadData();
    }, [raceId]);

    useEffect(() => {
        if (params['id'] !== undefined)
            setRaceId(Number(params['id']))
        else setRaceId(undefined)
    }, [params]);

    const reloadData = () => {
        if (raceId !== undefined)
            new ApiBaseClient().GetAuthedClient().getRaceAttendeesCurrentRace(Number(params['id'])).then((data) => {
                if (data.raceAttendees)
                    setAttendees(data.raceAttendees);
                setRaceInfo({name: data.raceName!, dateRange: data.raceDate!, place: data.racePlace!});
            });
        else setAttendees([]);
    }
    return (
        <div style={{margin: '0px -120px'}}>
            <div style={{textAlign: 'center'}}><Typography variant='h4' color='#D82C90'>KONTROLA ÚČASTNÍKŮ</Typography></div>
            <div style={{position: 'absolute', left: '4%', top: '6%'}}><Button onClick={() => { navigate('/eventsadmin') }}>Zpět </Button></div>
            {attendees.map((attendee, i) => {
            return <div key={i} style={{ display: 'flex', alignItems: 'center', margin:'12px', border: '1px solid #D82C90', padding: '12px', borderRadius: '4px', justifyContent: 'space-between' }}>
                <div style={{ width: '240px' }}><Typography variant='body1'>{attendee.firstName} {attendee.lastName}</Typography></div>
                <div style={{ width: '300px' }}><Typography variant='body1'>{attendee.car}</Typography></div>
                <TextField
                    label='číslo'
                    autoSave='10'
                    error={attendees.some((p) => (p.startNumber === attendee.startNumber && p.id !== attendee.id))}
                    type='number' value={attendee.startNumber} onChange={(e) => {
                        new ApiBaseClient().GetAuthedClient().updateRaceAttendeesBeforeStart(new UpdateRaceAttendeesBeforeStartRequest({
                            attendeeId: attendee.id,
                            startNumber: e.target && e.target.value? Number(e.target.value) : undefined,
                            signatureBase64: undefined
                        }))/* .then(() => reloadData()) */;
                        setAttendees(prev => {
                            return prev.map(p => {
                                if (p.id === attendee.id)
                                    return {...attendee, startNumber: e.target && e.target.value ? Number(e.target.value) : undefined}
                                else 
                                    return p;
                            });
                        });
                    }}>
                </TextField>
                <FormControlLabel
                    value="end"
                    sx={{ marginLeft: '6px' }}
                    control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={attendee.pro}
                    
                    />}
                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>PRO</Typography>}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value="end"
                    sx={{ marginLeft: '6px' }}
                    control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={attendee.street}
                   
                    />}
                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>STREET</Typography>}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value="end"
                    sx={{ marginLeft: '6px' }}
                    control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={attendee.technicalOk}
                    onChange={(v) => {
                        new ApiBaseClient().GetAuthedClient().setRaceAttendeeTechnicalOk(new SetRaceAttendeeTechnicalOkRequest({
                            attendeeId: attendee.id,
                            isOk: !attendee.technicalOk
                        })).then((res) => {
                            if (res.ok)  
                                setAttendees(attendees.map(p => {                            
                                    if (p.id === attendee.id)
                                        return {...attendee, technicalOk: !attendee.technicalOk}
                                    else 
                                        return p;
                                }
                            ))
                        })
                    }}
                    />}
                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>TP</Typography>}
                    labelPlacement="end"
                />
                {attendee.signatureBase64 !== null && <Check sx={{ width: '40px'}}/>}
                {attendee.signatureBase64 === null && <div style={{ width: '44px'}}/>}
                <Button variant='contained' onClick={() => { setSelectedAttendee(attendee as RaceAttendeeStartRaceDTO); setDisclaimerOpen(true); }}>Souhlas</Button>
            </div>;
        })}
        {raceInfo && selectedAttendee && <DisclaimerDialog isOpen={disclaimerOpen} onClose={() => { setDisclaimerOpen(false); reloadData(); } } raceInfo={raceInfo} raceAttendee={selectedAttendee}/>}
        <Button variant='contained' sx={{height: '100px', margin: '12px', width: 'calc(100% - 24px)'}} onClick={() =>{ navigate("/qualification/" + raceId);}}><Typography variant='h4'>KVALIFIKACE</Typography></Button>
        </div>
    )
}
