import { Dialog, DialogContent, Typography } from '@mui/material';
import React from 'react'
import SuccessPayment from './SuccessPayment';

interface Props {
    isOpen: boolean;
    onClose: (args: { street: number, pro: number } | null) => void;
}

export default function SuccessRegistrationDialogNoPay(props: Props) {

    const { isOpen, onClose } = props;
    return (
        <Dialog maxWidth='lg' fullScreen sx={{ minHeight: '300px' }} onClose={() => onClose(null)} open={isOpen}>
            <DialogContent sx={{ background: 'white' }}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{ border: '1px solid #D82C90', borderRadius: '8px', padding: '40px', maxWidth: '600px' }}>
                        <div>
                            <Typography variant='h4' textAlign='center'>
                                Potvrzení o  platbě a další informace k závodu najdeš ve svém emailu.
                                
                                
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src='https://i.gifer.com/7efs.gif' style={{ width: '160px' }}></img>
                            
                        </div>
                        <Typography variant='h6' textAlign='center'>STRÁNKU MŮŽEŠ ZAVŘÍT</Typography>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

