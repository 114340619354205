import { createTheme, Theme, ThemeOptions } from "@mui/material";
const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#D82C90',
            light: '#404040',
            dark: '#000000',
        },
        warning: {
            main: '#FFB905',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            default: '#F5F5F5',
        },
        text: {
            primary: '#404040',
            secondary: '#777777',
        },
        error: {
            main: '#DD403A',
        },
        success: {
            main: '#85CB33',
        },
        divider: '#E6E6E6',
    },
    typography: {
        fontFamily: 'Big Shoulders Text',
        fontSize: 20,
        h1: {
            fontFamily: 'Big Shoulders Text',
        },
        h2: {
            fontFamily: 'Big Shoulders Text',
        },
        h3: {
            fontFamily: 'Big Shoulders Text',
        },
        h4: {
            fontFamily: 'Big Shoulders Text',
        },
        h5: {
            fontFamily: 'Big Shoulders Text',
        },
        h6: {
            fontFamily: 'Big Shoulders Text',
        },
        body1: {
            fontFamily: 'Montserrat',
            fontSize: '1rem',
        },
        body2: {
            fontFamily: 'Montserrat',
            fontSize: '1rem',
        },
    },
    shape: {
        borderRadius: 4,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold', // Set the font weight to 'bold' for all buttons
                },
            },
        },
    },
};
export default function CreateDriftTheme(): Theme {
    return createTheme(themeOptions)
}