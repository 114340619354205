import { Alert, Button, Checkbox, FormControlLabel, Snackbar, TextField, Typography } from '@mui/material'
import { Editor, } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useEffect, useState } from 'react'
import draftToHtml from "draftjs-to-html";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FileParameter, RaceDTO, UpdateRaceRequest } from '../Api/ApiServerDC';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { useNavigate } from 'react-router';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/cs';

interface RaceDetaiProps {
    inputRace?: RaceDTO;
}

export default function RaceDetail(props: RaceDetaiProps) {
    const [photoDeleted, setPhotoDeleted] = useState(false);
    const [saveErrorOpen, setSaveErrorOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    const saveRace = () => {
        if (props.inputRace)
            updateRaceRace();
        else
            createNewRace();
        // if()
    }

    useEffect(() => { setPhotoDeleted(false)}, [props.inputRace]);

    const [file, setFile] = useState<FileParameter | null>(null);
    const createNewRace = () => {
        console.log(event);
        if (event !== null && file !== null) {
            // let newEvent = new CreateRaceRequest({
            //     capacity: event.capacity,
            //     dateEnd: event.dateEnd,
            //     dateStart: event?.dateStart,
            //     firstText: event?.firstText,
            //     formattedText: event?.formatedText,
            //     // : event?.formatedText,
            //     name: event?.name,
            //     place: event?.place,
            //     registrationFee: event?.registrationFee,

            //     secondText: event?.secondText

            // });
            // console.log(newEvent);
            new ApiBaseClient().GetAuthedClient().createEvent(event.name, event.dateStart, event.dateEnd, event.capacity, event.place, event.registrationFee && event.isPaid ? event.registrationFee : 0, /* event.isUpcoming */true, event.hasProCategory ? event.hasProCategory : false, event.hasStreetCategory ? event.hasStreetCategory : false, event.formatedText, event.firstText, event.secondText, event.whatsAppMessage, event.gps, file, event.isPaid ? event.isPaid : false, event.isMeeting ? event.isMeeting : false, event.freeCode).then(() => {
                navigate('/eventsadmin');
            })
            // new ApiBaseClient().GetClientToAuth().createEvent(file).then(() => {
            //         navigate('/events');
            //     })
        }
        else setSaveErrorOpen(true);

    }
    const updateRaceRace = () => {
        if (event === null) return;        
        new ApiBaseClient().GetAuthedClient().updateEvent(new UpdateRaceRequest({
            id: props.inputRace?.id,
            capacity: event?.capacity,
            dateEnd: event?.dateEnd,
            dateStart: event?.dateStart,
            firstText: event?.firstText,
            formattedText: event?.formatedText,
            hasProCategory: event?.hasProCategory,
            hasStreetCategory: event?.hasStreetCategory,
            // : event?.formatedText,
            name: event?.name,
            place: event?.place,
            registrationFee: event?.isPaid === true ? event?.registrationFee : 0,
            secondText: event?.secondText,
            isPaid: event?.isPaid ? event?.isPaid : false,
            isMeeting: event?.isMeeting,
            freeCode: event?.freeCode
        })).then(() => {
            navigate('/eventsadmin');
        })
    }
    useEffect(() => {
        if (props.inputRace) {
            if (props.inputRace.formatedText !== undefined) {
                const blocks = convertFromHTML(props.inputRace.formatedText);
                // console.log(blocks);
                let a = EditorState.createWithContent(ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap));
                setEditorState(a);
            }
            
            if (props.inputRace.secondText !== undefined) {
                const blocks2 = convertFromHTML(props.inputRace.secondText);
                // console.log(blocks);
                let a2 = EditorState.createWithContent(ContentState.createFromBlockArray(blocks2.contentBlocks, blocks2.entityMap));
                setEditorState2(a2);
            }
            setEvent(props.inputRace);
        }

        else {
            setEvent(new RaceDTO({ isUpcoming: true }));
            setEditorState(EditorState.createEmpty());
            setEditorState2(EditorState.createEmpty());
        }
    }, []);
    const [editorState, setEditorState] = useState<EditorState | null>(null);
    const [editorState2, setEditorState2] = useState<EditorState | null>(null);
    const [changed, setChanged] = useState(false);
    const [changedPhoto, setChangedPhoto] = useState<FileParameter | null>(null);


    const [event, setEvent] = React.useState<Partial<RaceDTO> | null>(null);

    const deletePhoto = (raceId: number) => {
        new ApiBaseClient().GetAuthedClient().deletePhoto(raceId).then(() => { 
            navigate('/eventsadmin');
            setPhotoDeleted(true);
        });
    }
    const uploadAnotherPhoto = (raceId: number) => {
        if (changedPhoto !== null)
            new ApiBaseClient().GetAuthedClient().updatePhoto(raceId, changedPhoto).then(() => { navigate('/events') })
    }
    if (event !== null)
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button onClick={saveRace} disabled={!changed || saving} variant='contained' style={{ alignSelf: 'right', width: '200px', margin: '12px' }}>Uložit</Button>
                <TextField value={event.name} onChange={(e) => { setEvent({ ...event, name: e.currentTarget.value }); setChanged(true); }} sx={{ margin: '12px' }} label='Název' />

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}><LocalizationProvider adapterLocale='cs' dateAdapter={AdapterDayjs} >
                        <DateTimePicker sx={{ marginY: '12px', marginLeft: '12px', marginRight: '8px', width: '35%' }} format="DD.MM.YYYY HH:mm"
                            // inpu={(params) => <TextField size='small' {...params} />}
                            ampm={false} label="Start" value={event.dateStart !== undefined ? dayjs(event.dateStart) : null} onChange={(value) => { setEvent({ ...event, dateStart: value !== null ? value.toDate() : undefined }); setChanged(true); }} />
                        <DateTimePicker sx={{ margin: '12px 12px 0px 0px', width: '35%' }} format="DD.MM.YYYY HH:mm"
                            ampm={false} label="Konec" value={event.dateEnd !== undefined ? dayjs(event.dateEnd) : null} onChange={(value) => { setEvent({ ...event, dateEnd: value !== null ? value.toDate() : undefined }); setChanged(true); }} />
                    </LocalizationProvider>
                    
                    <TextField value={event.freeCode} onChange={(e) => { setEvent({ ...event, freeCode: e.currentTarget.value }); setChanged(true); }} sx={{ margin: '12px', width: '30%' }} label='Kód pro registraci zdarma' />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormControlLabel
                        value="end"
                        sx={{ marginLeft: '6px' }}
                        control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={event.isMeeting}
                            onChange={(e, checked) => { setEvent({ ...event, isMeeting: checked }); setChanged(true); }} />}
                        label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>SRAZ</Typography>}
                        labelPlacement="end"
                    />
                    {!event.isMeeting && <FormControlLabel
                        value="end"
                        sx={{ marginLeft: '6px' }}
                        control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={event.hasProCategory}
                            onChange={(e, checked) => { setEvent({ ...event, hasProCategory: checked }); setChanged(true); }} />}
                        label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>PRO</Typography>}
                        labelPlacement="end"
                    />}
                    {!event.isMeeting && <FormControlLabel
                        value="end"
                        sx={{ marginLeft: '6px' }}
                        control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={event.hasStreetCategory}
                            onChange={(e, checked) => { setEvent({ ...event, hasStreetCategory: checked }); setChanged(true); }} />}
                        label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>STREET</Typography>}
                        labelPlacement="end"
                    />}
                    <FormControlLabel
                    value="end"
                    sx={{ marginLeft: '6px' }}
                    control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={event.isPaid}
                        onChange={(e, checked) => {
                            if (checked === false)
                                setEvent({ ...event, isPaid: checked, registrationFee: 0 });
                            else setEvent({ ...event, isPaid: checked });
                            setChanged(true);
                        }} />}
                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Placený závod</Typography>}
                    labelPlacement="end"
                />
                <TextField disabled={!event.isPaid} sx={{ margin: '12px', width: '23%' }} value={event.registrationFee} onChange={(e) => { setEvent({ ...event, registrationFee: Number(e.currentTarget.value) }); setChanged(true); }} label='Cena' type='number' />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <TextField value={event.place} onChange={(e) => { setEvent({ ...event, place: e.currentTarget.value }); setChanged(true); }} sx={{ margin: '12px', width: '32%' }} label='Místo' />
                    <TextField value={event.gps} onChange={(e) => { setEvent({ ...event, gps: e.currentTarget.value }); setChanged(true); }} sx={{ margin: '12px', width: '32%' }} label='GPS souřadnice' />
                    <TextField value={event.capacity} onChange={(e) => { setEvent({ ...event, capacity: Number(e.currentTarget.value) }); setChanged(true); }} sx={{ margin: '12px', width: '32%' }} type='number' label='Kapacita' />
                    
                </div>
                <TextField value={event.firstText} multiline onChange={(e) => { setEvent({ ...event, firstText: e.currentTarget.value }); setChanged(true); }} sx={{ margin: '12px' }} label='První text' />
                {/* <TextField value={event.secondText} multiline onChange={(e) => { setEvent({ ...event, secondText: e.currentTarget.value }); setChanged(true); }} sx={{ margin: '12px' }} label='Druhý text' />
                 */}
                {editorState2 !== null && <div style={{ margin: '12px', border: '1px solid #A9A9A9', borderRadius: '8px', padding: '2px' }}>
                    <Editor
                        editorState={editorState2}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorStyle={{ maxHeight: '300px', minHeight: '300px', borderTop: '1px solid #A9A9A9' }}
                        onEditorStateChange={(es) => { setEditorState2(es); setEvent({ ...event, secondText: draftToHtml(convertToRaw(es.getCurrentContent())) }); setChanged(true); }}
                    />
                </div>}
                
                <TextField value={event.whatsAppMessage} multiline onChange={(e) => { setEvent({ ...event, whatsAppMessage: e.currentTarget.value }); setChanged(true); }} sx={{ margin: '12px' }} label='Whatsapp' />
                {props.inputRace === undefined && <div style={{ margin: '12px'}}><input type='file'
                    accept="image/png, image/jpeg, image/jfif"
                    onChange={(e) => {
                        if (e.target && e.target.files) {
                            let fileParameter: FileParameter = { data: e.target.files[0], fileName: e.target.files[0].name };
                            // console.log(fileParameter);
                            setFile(fileParameter);
                        }; 
                        setChanged(true);

                    }} id='file' /> </div>}
                {props.inputRace !== undefined && <div style={{ display: 'flex', flexDirection: 'column'}}>
                    {photoDeleted === false && <img style={{ maxWidth: '200px' }} src={process.env.REACT_APP_API_URL + '/RaceAnonymous/getRacePhoto/' + props.inputRace.id}></img>}
                    <Button onClick={() => deletePhoto(props.inputRace!.id!)} disabled={changedPhoto !== null} sx={{ alignSelf: 'start' }} variant='contained'>Smazat</Button>
                    nebo nahrát novou
                    <input type='file'
                        disabled={changedPhoto !== null}
                        accept="image/png, image/jpeg, image/jfif"
                        onChange={(e) => {
                            if (e.target && e.target.files) {
                                let fileParameter: FileParameter = { data: e.target.files[0], fileName: e.target.files[0].name };
                                // console.log(fileParameter);
                                setChangedPhoto(fileParameter);
                            }; 
                            setChanged(true);

                        }} id='file'></input>
                    {changedPhoto !== null && <Button onClick={() => { uploadAnotherPhoto(props.inputRace!.id!) }} sx={{ alignSelf: 'start' }} variant='contained'>Uložit</Button>}
                </div>}
                

                {editorState !== null && <div style={{ margin: '12px', border: '1px solid #A9A9A9', borderRadius: '8px', padding: '2px' }}>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorStyle={{ maxHeight: '300px', minHeight: '300px', borderTop: '1px solid #A9A9A9' }}
                        onEditorStateChange={(es) => { setEditorState(es); setEvent({ ...event, formatedText: draftToHtml(convertToRaw(es.getCurrentContent())) }); setChanged(true); }}
                    />
                </div>}
                <Snackbar open={saveErrorOpen} autoHideDuration={3000} onClose={() => setSaveErrorOpen(false)}>

                    <Alert onClose={() => setSaveErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
                        Chyba při ukládání - vyplňte všechny povinné údaje
                    </Alert>

                </Snackbar>
            </div>
        )
    else return <div>bad data</div>
}
