import axios, { AxiosInstance } from "axios";
import { DriftcorpClient } from "./ApiServerDC";


export class ApiBaseClient {
    protected GetApiAddress(): string {
        // console.log(process.env.REACT_APP_API_URL);
        // if (process.env.REACT_APP_API_URL === 'NOT')
        //     return '';
        // console.log(process.env.REACT_APP_API_URL);
        return process.env.REACT_APP_API_URL!;
        // return 'https://localhost:7186';
    }
    public GetAuthedClient(): DriftcorpClient {
        return new DriftcorpClient(this.GetApiAddress(), this.GetAuthClient());
    }
    private GetAuthClient(): AxiosInstance {
        const axiosApiInstance = axios.create();

        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {
                let token = localStorage.getItem('token');
                // let culture = localStorage.getItem('i18nextLng');
                // if (culture !== undefined && culture !== null) {
                if (token !== null && config && config.headers) {
                    
                    config.headers.Authorization = 'Bearer ' + token;
                    // config.headers.set('TimeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
                    // config.headers.set('accept-language', culture);
                    config.headers.Accept = 'application/json';
                    config.headers["Content-Type"] = 'application/json';
                }
                return config;

                //                 if (culture !== undefined && culture !== null)
                //                     config.headers = {
                //                         'Content-Type': 'application/json',
                //                         'Accept': 'application/json',
                //                         'accept-language': culture,
                //                         'TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
                //                     }
                //                 else config.headers = {
                // set('Authorization', 'Bearer ' + token)
                //                 }

            },
            error => {
                Promise.reject(error)
            });
        axiosApiInstance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            // if (error.response.status === 401) {
            //     console.log('401.1', window.location.href);
            //     var items = window.location.href.split("/");
            //     window.location.href = window.location.origin + '/' + items[3] + '/' + items[4];
            // }
            if (error.response.status === 401 && !originalRequest._retry) {
                window.location.href = window.location.origin;
                /* console.log('tady');
                console.log(error);
                // console.log(response);
                try {
                    if (error.response.headers['is-token-expired'] === 'true') {
                      

                        return axiosApiInstance(originalRequest);
                    }
                    else throw new Error('unauthorized but refresh token not expires');
                }
                catch (e) {
                    console.log('error refresh', e);
                    // throw new Error('unable to refresh token');
                } */
            }
            return Promise.reject(error);
        });
        return axiosApiInstance;
    }
    // public GetApiAddressToPDFViewer(): string {
    //     if (process.env.REACT_APP_API_URL === 'NOT')
    //         return 'https://localhost:5001/todos/api/PDF';
    //     else return process.env.REACT_APP_API_URL! + '/todos/api/PDF';
    // }
    public GetClientToAuth(): DriftcorpClient {
        const axiosApiInstance = axios.create();

        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {
                // let culture = localStorage.getItem('i18nextLng');
                // config.headers.set('TimeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
                // // config.headers.set('accept-language', culture);
                // config.headers.Accept = 'application/json';
                // config.headers["Content-Type"] = 'application/json';

                return config;
            },
            error => {
                Promise.reject(error)
            });
        axiosApiInstance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401) {
                console.log('401.1', window.location.href);
                var items = window.location.href.split("/");
                window.location.href = window.location.origin;
            }
            // if (error.response.status === 401 && !originalRequest._retry) {
            //     try {
            //         if (error.response.headers['is-token-expired'] === 'true') {
            //             console.log('refresh token', error);
            //             originalRequest._retry = true;
            //             await new APIUsersHelper().RefreshToken();
            //             axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('bearer');
            //             return axiosApiInstance(originalRequest);
            //         }
            //         else throw new Error('unathorized but refresh token not expires');
            //     }
            //     catch (e) {
            //         throw new Error('unable to refresh token');
            //     }
            // }
            return Promise.reject(error);
        });
        return new DriftcorpClient(this.GetApiAddress(), axiosApiInstance);
    }
}