import { Alert, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Slider, Snackbar, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CategoryForEventDTO, RaceAttendeeDTO, RacerPointInQualifyDTO, SaveRacerPointsInQualifyRequest, SendQualificationUpdateRequest } from '../Api/ApiServerDC';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { ChevronLeft, ChevronLeftOutlined, ChevronRight, Close, Done } from '@mui/icons-material';
interface Props {
    isOpen: boolean;
    attendees: RaceAttendeeDTO[];
    categories: CategoryForEventDTO[];
    currentStartNumber: number;
    roundOne: boolean;
    isPro: boolean;
    raceId: number;
    onClose: () => void;
    onChangeCurrentStartNumber: (startNumber: number) => void;
    onChangeRound: () => void;
}

export default function QualificationRacerPointDialog(props: Props) {
    const { isOpen, onClose, attendees, categories, roundOne, currentStartNumber, isPro, raceId, onChangeCurrentStartNumber, onChangeRound } = props;
    const [currentStartNumberLocal, setCurrentStartNumberLocal] = useState<number>(props.currentStartNumber);
    const [currentRacer, setCurrentRacer] = useState<RaceAttendeeDTO | undefined>(undefined);
    const [points, setPoints] = useState<RacerPointInQualifyDTO[]>([]);
    const [attendeesLocal, setAttendeesLocal] = useState<RaceAttendeeDTO[]>([]);
    const [changed, setChanged] = useState(false);
    const [forward, setForward] = useState<boolean | undefined>();

    const refreshRacer = () => {
        if (currentRacer && currentRacer.id)
            new ApiBaseClient().GetAuthedClient().getRacerPointInQualify(currentRacer.id, isPro).then((points) => {
                if (points.pointsInCategories) {
                    var currentPoints: RacerPointInQualifyDTO[] = [];
                    for (let category of categories) {
                        let existingPoints = points.pointsInCategories.find(x => x.categoryId === category.id);
                        if (existingPoints !== undefined) {
                            currentPoints.push(existingPoints);
                        }
                        else currentPoints.push(new RacerPointInQualifyDTO({ categoryId: category.id, id: -1, pointsOne: 0, pointsTwo: 0, categoryName: category.name, maximalPoints: category.maximalPoints}));
                    }
                    setPoints(currentPoints);
                    setChanged(false);
                }
            });
    }
    useEffect(() => {
        refreshRacer();        
    }, [currentRacer]);

    
    useEffect(() => {
        setAttendeesLocal(attendees);
    }, [attendees]);

    useEffect(() => {
        let newAttendee = attendeesLocal.find(x => x.startNumber === currentStartNumberLocal);

        if (newAttendee === undefined) {
            let currStart = currentStartNumberLocal;
            if (forward !== false){
                while (newAttendee === undefined && currStart < 1000) {
                    currStart++;
                    newAttendee = attendeesLocal.find(x => x.startNumber === currStart);
                }
            }
            else {
                while (newAttendee === undefined && currStart > 0) {
                    currStart--;
                    newAttendee = attendeesLocal.find(x => x.startNumber === currStart);
                }
            }
            if (newAttendee !== undefined) 
                onChangeCurrentStartNumber(currStart);
            else
                setCurrentRacer(undefined);
        }
        else
            setCurrentRacer(attendeesLocal.find(x => x.startNumber === currentStartNumberLocal));
    }, [currentStartNumberLocal, attendeesLocal]);

    useEffect(() => {
        setCurrentStartNumberLocal(currentStartNumber);
    }, [currentStartNumber]);

    const handlePointsChange = (categoryId: number, newValue: number, pointsOne: boolean, maxPoints: number) => {
        //onEdit();
        const updatedPoints = points.map(point => {
            if (point.categoryId === categoryId) {

                if (newValue > maxPoints)
                    newValue = maxPoints;
                if (newValue < 0)
                    newValue = 0;

                setChanged(true);
                if (pointsOne)
                    return new RacerPointInQualifyDTO({ categoryId: point.categoryId, id: point.id, pointsOne: newValue, pointsTwo: point.pointsTwo, isChanged: true, categoryName: point.categoryName, maximalPoints: point.maximalPoints});
                else return new RacerPointInQualifyDTO({ categoryId: point.categoryId, id: point.id, pointsOne: point.pointsOne, pointsTwo: newValue, isChanged: true, categoryName: point.categoryName, maximalPoints: point.maximalPoints});
            }
            return point;
        });
        setPoints(updatedPoints);

        /* if (racer.id)
            onChange(racer.id, isPro, updatedPoints); */
    };
    const [saving, setSaving] = useState<boolean>(false);
    const saveChanges = async (attendeeId: number, close: boolean, zero: boolean) => {
        setSaving(true);
        await new ApiBaseClient().GetAuthedClient().saveRacerPointsInQualify(new SaveRacerPointsInQualifyRequest({ attendeeId: attendeeId, isPro: isPro, points: zero? points.map((p) => new RacerPointInQualifyDTO({...p, isChanged: true, pointsOne: roundOne ? 0 : p.pointsOne, pointsTwo: roundOne ? p.pointsTwo : 0})) : points, firstRound: roundOne })).then((res) => {
            setSaving(false);
            setSaved(true);
            setAttendeesLocal(prev => {
                return prev.map(p => {
                    if (p.id === attendeeId)
                        if (roundOne)
                            return new RaceAttendeeDTO({...p, doneOne: true});
                        else 
                            return new RaceAttendeeDTO({...p, doneTwo: true});
                    else 
                        return p;
                });
            });
            if (res.ok && close){
                setForward(undefined);
                onClose();
            }

            new ApiBaseClient().GetAuthedClient().sendQualificationUpdate(new SendQualificationUpdateRequest({ raceId: raceId}));
        });
        
        if (zero){
            setPoints(points.map((p) => new RacerPointInQualifyDTO({...p, isChanged: true, pointsOne: roundOne ? 0 : p.pointsOne, pointsTwo: roundOne ? p.pointsTwo : 0})));
        }
        
    }

    const [saved, setSaved] = useState(false);

    const getBasicBackgroundColor = () => {
        return roundOne ? 'white' : 'aquamarine';
    }

    return (
        <Dialog maxWidth='lg' sx={{ minHeight: '300px' }} onClose={() => { setForward(undefined); onClose();}} open={isOpen}>
            <DialogTitle sx={{ background: getBasicBackgroundColor() }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ display: 'flex', gap: '8px', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ border: '1px solid gray', borderRadius: '16px'}}>
                            <IconButton sx={{ width: '150px', height: '75px'}} onClick={() => { setForward(false); onChangeCurrentStartNumber(currentStartNumberLocal - 1);}}>
                                <ChevronLeft/> 
                            </IconButton>
                        </div>
                        <div>
                            <Button variant='outlined' onClick={() => onChangeRound()}>
                                <Typography variant='h6'>{roundOne ? 'První kolo' : 'Druhé kolo'}</Typography>
                            </Button>
                        </div>
                        <div style={{ border: '1px solid gray', borderRadius: '16px', marginRight: '32px' }}>
                            <IconButton sx={{ width: '150px', height: '75px'}} onClick={() => { setForward(true); onChangeCurrentStartNumber(currentStartNumberLocal + 1)}}>
                                <ChevronRight />
                            </IconButton>
                        </div>
                    </div>
                    <IconButton sx={{ width: '40px'}} onClick={() => onClose()}>
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent sx={{ background: getBasicBackgroundColor() }}>
                <div style={{ width: '600px'}}>
                    {currentRacer !== undefined && <div> 
                        <div>
                            <div style={{ display: 'flex', gap: '8px', background: (currentRacer.doneOne && roundOne) || (currentRacer.doneTwo && !roundOne) ? "lightgray" : getBasicBackgroundColor() }}>
                                <Typography variant='h4'>{'#' + currentRacer.startNumber}</Typography>
                                <Typography noWrap variant='h3'>{currentRacer.firstName}</Typography>
                                <Typography noWrap variant='h3'>{currentRacer.lastName}</Typography>
                            </div>
                        </div>
                        <div style={{ marginTop: '16px', marginLeft: '8px' }}>
                            {points.map((cat, i) => {
                                return <div key={i}>
                                    <Typography variant='h6'>{cat.categoryName}{' (' + cat.maximalPoints + ')'}</Typography>
                                    {roundOne && cat.categoryId !== undefined &&
                                        <div style={{ display: 'flex', gap: '16px' }}>
                                            <div style={{ width: 'calc(100% - 70px)', paddingTop: '16px'}}>
                                                <Slider value={cat.pointsOne} onChange={(e, newValue) => handlePointsChange(cat.categoryId!, newValue as number, true, cat.maximalPoints)} min={0} max={cat.maximalPoints}/>
                                            </div>
                                            <div>
                                                <TextField type='number' onFocus={(e) => { e.target.select(); }} variant='filled' sx={{ width: '68px', height: '55px', backgroundColor: '#D82C90' }} inputProps={{ min: 0, max: cat.maximalPoints, style: { textAlign: 'right', color: 'white', fontWeight: 'bold' } }} value={cat.pointsOne} onChange={(e) => { handlePointsChange(cat.categoryId!, Number(e.target.value), true, cat.maximalPoints);}} onWheel={(e) => e.preventDefault()}/>
                                            </div>
                                        </div>}
                                    {!roundOne && cat.categoryId !== undefined &&
                                        <div style={{ display: 'flex', gap: '16px' }}>
                                        <div style={{ width: 'calc(100% - 70px)', paddingTop: '16px'}}>
                                            <Slider value={cat.pointsTwo} onChange={(e, newValue) => handlePointsChange(cat.categoryId!, newValue as number, false, cat.maximalPoints)} min={0} max={cat.maximalPoints}/>
                                        </div>
                                        <div>
                                            <TextField type='number' onFocus={(e) => { e.target.select(); }} variant='filled' sx={{ width: '68px', height: '55px', backgroundColor: '#D82C90' }} inputProps={{ min: 0, max: cat.maximalPoints, style: { textAlign: 'right', color: 'white', fontWeight: 'bold' } }} value={cat.pointsTwo} onChange={(e) => { handlePointsChange(cat.categoryId!, Number(e.target.value), false, cat.maximalPoints);}} onWheel={(e) => e.preventDefault()}/>
                                        </div>
                                    </div>}

                                </div>
                            })
                            }
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'right'}}>
                                <Typography variant='h6'>Celkem: {points.reduce((acc, val) => acc + (roundOne ? val.pointsOne : val.pointsTwo), 0)}</Typography>
                            </div>                       
                        </div>
                        <div style={{ marginTop: '24px'}}>
                            {saving && <CircularProgress />}
                            {!saving && <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '16px'}}>
                            </div>}
                            {!saving && <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                                <Button variant='outlined' onClick={() => saveChanges(currentRacer.id!, false, false)}>Uložit</Button>
                                <Button variant='outlined' onClick={() => saveChanges(currentRacer.id!, false, true)}>Odjeto za nula</Button>
                                <Button variant='outlined' onClick={() => saveChanges(currentRacer.id!, true, false) }>Uložit a zavřít</Button>
                            </div>}
                        </div>
                    </div>}
                </div>
                {currentRacer === undefined && <div style={{ width: '600px', wordWrap: 'break-word'}}>
                    <Typography variant='h5'>{'Závodník se startovním číslem #' + currentStartNumberLocal + ' nenalezen'}</Typography>
                    </div>}
                
                    <Snackbar open={saved} autoHideDuration={3000} onClose={() => setSaved(false)}>
                        <Alert onClose={() => setSaved(false)} severity="success" sx={{ width: '100%' }}>
                            Uloženo
                        </Alert>
                    </Snackbar>
            </DialogContent>
        </Dialog>
    )
}
