import React, { useEffect } from 'react'
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { RaceDTO } from '../Api/ApiServerDC';
import { Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';

export default function RacesList() {
    const [races, setRaces] = React.useState<RaceDTO[]>([]);
    const theme = useTheme();
    const biggerThan700 = useMediaQuery(theme.breakpoints.up(700));
    const navigate = useNavigate();
    useEffect(() => {
        new ApiBaseClient().GetClientToAuth().eventsPublic().then((res) => {
            if (res && res.races)
                setRaces(res.races);
        });
    }, []);
    return (
        <div style={{ textAlign:'center', margin: biggerThan700? '' : '0px -160px' }}>
            <div style={{ fontSize: biggerThan700 ? '60px' : '40px', fontWeight: 'bold', marginBottom: '20px' }}>NADCHÁZEJÍCÍ EVENTY</div>
            <div style={{
                display: 'flex', flexWrap: 'wrap', overflow: 'hidden',
                textOverflow: 'ellipsis', justifyContent: 'start',
            }}>
                {races.filter(x => x.isUpcoming).map((r) => {
                    return <div

                        onClick={() => { navigate('/event/' + r.id) }}
                        className='event-card'
                        style={{
                            height: 'auto',
                            maxWidth: '368px',
                            overflow: 'hidden',
                            whiteSpace: 'pre-wrap',
                            textOverflow: 'ellipsis',
                            marginRight: '16px',
                            borderRadius: '4px',
                            width: biggerThan700 ? '30vw' : '100%',
                        }}>
                        <img style={{ width: '100%', maxHeight: '200px', minHeight: '200px', objectFit: 'cover' }} src={process.env.REACT_APP_API_URL + '/RaceAnonymous/getRacePhotoThumbnail/' + r.id}></img>
                        <Tooltip title={<div style={{ fontFamily: 'Montserrat' }}>{r.name}</div>}>
                            <div style={{
                                textAlign: 'left', 
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginLeft: '10px',
                                marginRight: '10px',
                                marginTop: biggerThan700 ? '8px' : '12px', fontSize: biggerThan700 ? '20px' : '16px', fontWeight: 'bold'
                            }}><Typography variant='h6' fontWeight='bold' mt='-4px' >{r.name}</Typography></div></Tooltip>
                    </div>
                })}
            </div>
            <div style={{ fontSize: biggerThan700 ? '60px' : '40px', fontWeight: 'bold', marginBottom: '16px' }}>UPLYNULÉ EVENTY</div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
                {races.filter(x => !x.isUpcoming).map((r) => {
                    return <div
                        onClick={() => { navigate('/event/' + r.id) }}
                        className='event-card'
                        style={{
                            height: '188px',
                            minWidth: '300px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            width: biggerThan700 ? '30vw' : '100%',
                        }}>
                        <div style={{
                            position: 'relative', // Set the parent container to relative positioning
                            maxHeight: '160px',
                            width: '100%',
                        }}>
                            <img style={{ width: '100%', maxHeight: '144px', minHeight: '144px', objectFit: 'cover' }} src={process.env.REACT_APP_API_URL + '/RaceAnonymous/getRacePhotoThumbnail/' + r.id}></img>
                            <div style={{
                                position: 'absolute', // Set the text div to absolute positioning
                                top: '0',
                                left: '0',
                                width: '100%', // Cover the full width of the parent
                                height: '100%', // Cover the full height of the parent
                                backgroundColor: 'rgba(80, 80, 80, 0.4)', // Background color for opacity
                            }} />
                            <div style={{
                                textAlign: 'start',
                                fontWeight: 'bold',
                                color: '#D82C90',
                                fontSize: '42px',
                                position: 'absolute', // Set the text div to absolute positioning
                                top: '50%', // Center vertically
                                left: '50%', // Center horizontally
                                transform: 'translate(-50%, -50%)', // Center the text within the parent
                                whiteSpace: 'nowrap', // Prevent text wrapping
                                padding: '10px', // Add padding for better visibility
                            }}>UPLYNULÁ UDÁLOST</div>
                        </div>
                        <Tooltip title={<div style={{ fontFamily: 'Arial' }}>{r.name}</div>}>
                            <div style={{
                                textAlign: 'center', overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginLeft: '10px',
                                marginRight: '10px',
                                marginTop: biggerThan700 ? '8px' : '12px', fontSize: biggerThan700 ? '20px' : '16px', fontWeight: 'bold'
                            }}><Typography variant='h6' fontWeight='bold' mt='-8px'>{r.name}</Typography></div></Tooltip>
                    </div>
                })}
            </div>
        </div >
    )
}
