import { TextField, Button, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Passwordbox from '../Shared/PasswordBox';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { AuthRequest } from '../Api/ApiServerDC';
import { useNavigate } from 'react-router';


export default function Login() {
    const theme = useTheme();
    const [userName, setUserName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const navigate = useNavigate();
    const biggerThan900 = useMediaQuery(theme.breakpoints.up(900));
    const login = () => {
        new ApiBaseClient().GetClientToAuth().login(new AuthRequest({ email: userName, password: password })).then((token) => {
            if (token && token.accessToken) {
                localStorage.setItem('token', token.accessToken);
                navigate('/eventsadmin');
            }
        });
    }
    return (
        <div style={{ margin: biggerThan900 ? '' : '0px -160px' }}>

            
            <TextField
                id="outlined-basic"
                fullWidth
                label={'Zadejte email'}
                className="text-field"
                sx={{ marginBottom: '12px', marginTop: '16px', height: '58px' }}
                value={userName}
                onChange={(e) => { setUserName(e.currentTarget.value) }}
                variant="outlined" />




            <Passwordbox password={password}
                onEnter={login}
                onPasswordChange={(e) => { setPassword(e) }}></Passwordbox>
            <Button onClick={login}>Přihlásit</Button></div>
    )
}
