import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { CategoryForEventDTO, ChangeRaceStateRequest, CreateBaseBracketForCategoryRequest, RaceAttendeeDTO, RaceDTO, RaceState } from '../Api/ApiServerDC';
import QualififyRacer from './QualififyRacer';
import { Button, Typography, useTheme, useMediaQuery, Checkbox, FormGroup, FormControlLabel, CircularProgress } from '@mui/material';
import SignalRConnector from './SinglaRConnector';
import QualificationEndDialog from './QualificationEndDialog';
import { ArrowBackOutlined, CheckOutlined, Done, PlayArrow, SaveAltOutlined, SaveRounded, Star } from '@mui/icons-material';
import QualificationRacerPointDialog from './QualificationRacerPointDialog';
import { start } from 'repl';

interface QualificationProps {
    isJury: boolean;
}

export default function Qualification(props: QualificationProps) {
    const theme = useTheme();
    const biggerThan900 = useMediaQuery(theme.breakpoints.up(900));
    const params = useParams();
    const [categories, setCategories] = useState<CategoryForEventDTO[]>([]);
    const [attendees, setAttendees] = useState<RaceAttendeeDTO[]>([]);
    const [firstRound, setFirstRound] = useState(true);
    const [together, setTogether] = useState(false);
    const [race, setRace] = useState<RaceDTO>();
    const [isPro, setIsPro] = useState(true);
    const [racerPointOpen, setRacerPointOpen ] = useState(false);
    const [currentRacerStartNumber, setCurrentRacerStartNumber ] = useState<number>(1);
    const [editingRacer, setEditingRacer] = useState<RaceAttendeeDTO>();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        refreshCategories();
        let connection = SignalRConnector([{ hubName: "QualificationUpdate", callback: (p) => signalUpdate(p) }, { hubName: "ChangeRaceState", callback: (p) => signalUpdateState(p) }]);
        return () => {
            connection.stop();
        };
    }, []);
    useEffect(() => {
        refreshCategories();
    }, [isPro]);
    const refreshCategories = () => {
        setLoading(true);
        if (params['raceID'] !== undefined && !Number.isNaN(params['raceID'])) {

            new ApiBaseClient().GetClientToAuth().getCategoriesForEvent(Number(params['raceID']), isPro).then((categories) => {
                if (categories.categories)
                    setCategories(categories.categories);
                // if (events.races)
                //     setRaces(events.races);
            });

            new ApiBaseClient().GetClientToAuth().raceDetail(Number(params['raceID'])).then(p => { 
                setRace(p.race);
            });
        }
    }
    const signalUpdate = useCallback((params: any) => {
        if (props.isJury) {
            //refreshing in onSaveEvent
        }
        else {
            refreshAttendees((params as RaceState) === RaceState.ProQualificationRoundOne || (params as RaceState) === RaceState.ProQualificationRoundTwo);
        }
    }, [categories, isPro, props.isJury]);
    const signalUpdateState = useCallback((params: any) => {
        if (props.isJury) {

        }
        else {
            if ((params as RaceState) === RaceState.ProQualificationRoundOne) {
                setIsPro(true);
                setFirstRound(true);
            }
            else if ((params as RaceState) === RaceState.ProQualificationRoundTwo) {
                setIsPro(true);
                setFirstRound(false);
            }
            else if ((params as RaceState) === RaceState.StreetQualificationRoundOne) {
                setIsPro(false);
                setFirstRound(true);
            }
            else if ((params as RaceState) === RaceState.StreetQualificationRoundTwo) {
                setIsPro(false);
                setFirstRound(false);
            }
            console.log(params);
        }
    }, [isPro, firstRound, props.isJury]);

    useEffect(() => {

        refreshAttendees(isPro);
    }, [categories]);
    const refreshAttendees = (isProo: boolean) => {
        setLoading(true);
        new ApiBaseClient().GetClientToAuth().getQualificationStatus(Number(params['raceID']), isProo).then((attendees) => {
            if (attendees.qualificationStatusesAttendees){
                let atts = attendees.qualificationStatusesAttendees.map((attendee) => {
                    return new RaceAttendeeDTO({
                        id: attendee.attendee?.id,
                        car: attendee.attendee?.car,
                        email: attendee.attendee?.email,
                        firstName: attendee.attendee?.firstName,
                        lastName: attendee.attendee?.lastName,
                        phone: attendee.attendee?.phone,
                        startNumber: attendee.attendee?.startNumber,
                        totalPoints: attendee.pointsSumOne > attendee.pointsSumTwo ? attendee.pointsSumOne : attendee.pointsSumTwo,
                        doneOne: attendee.doneOne,
                        doneTwo: attendee.doneTwo
                    })
                })
                setAttendees(atts);                
            }
            setLoading(false);
        });
    }
    const UpdateRaceState = (_firstRound: boolean, _isPro: boolean) => {
        setTimeout(() => {
            new ApiBaseClient().GetAuthedClient().changeRaceState(new ChangeRaceStateRequest({ raceId: Number(params['raceID']), toState: getCurrentRaceState(_firstRound, _isPro) }));
        }, 1000);        
    };
    const getCurrentRaceState = (_firstRound: boolean, _isPro: boolean) => {
        if (_firstRound && _isPro)
            return RaceState.ProQualificationRoundOne;
        else if (_firstRound && !_isPro)
            return RaceState.StreetQualificationRoundOne;
        else if (!_firstRound && _isPro)
            return RaceState.ProQualificationRoundTwo;
        else return RaceState.StreetQualificationRoundTwo;
    };

    const maxStartNumberAttendee = attendees && attendees.length > 0 ? (attendees.reduce((max, attendee) => {
        return attendee.startNumber > max.startNumber ? attendee : max;
    }, attendees[0]).startNumber) : 1;

    const nameWidth = 220;
    const orderWidth = 50;
    const numberWidth = 92;
    const pointsWidth = 140;
    const buttonsWidth = 80;
    const [qualificationEnding, setQualificationEnding] = useState(false);
    const navigate = useNavigate();
    return (
        <div style={{ margin: biggerThan900 ? '' : '0px -160px' }}>
            <QualificationEndDialog
                isOpen={qualificationEnding}
                onClose={(args) => {
                    if (args === null) {
                        setQualificationEnding(false);
                    }
                    else {
                        new ApiBaseClient().GetAuthedClient().createBaseBracketForCategory(new CreateBaseBracketForCategoryRequest({ attendeesCount: args.pro, isPro: true, raceId: Number(params['raceID']) })).then(() => {

                            new ApiBaseClient().GetAuthedClient().createBaseBracketForCategory(new CreateBaseBracketForCategoryRequest({ attendeesCount: args.street, isPro: false, raceId: Number(params['raceID']) })).then(() => {
                                navigate('/bracket/' + params['raceID']);
                            })
                        })
                        // Promise.resolve([promPro, promStreet]).then(()=>{

                        // })
                    }
                }}
            />
            <Typography variant='h5'>{race ? race.name : "loading ..."}</Typography>
            <div style={{ width: '100%', textAlign: 'center', display: biggerThan900 ? 'flex' : '', justifyContent: 'left', paddingBottom: '20px' }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant='h3' mr='16px' >KVALIFIKACE</Typography>
                    <Typography variant='h3' mr='16px' color='#D82C90' >{firstRound ? ' PRVNÍ KOLO' : ' DRUHÉ KOLO'}</Typography>
                </div>
                {!together && <Typography variant='h3' sx={{ display: 'flex' }}>KATEGORIE <Typography variant='h3' ml='16px' color='#D82C90'>{isPro ? ' PRO' : ' STREET'}</Typography></Typography>}
            </div>
            <div style={{ display: 'flex'}}>
                
                {props.isJury && <div style={{ display: 'flex', gap: '8px'}}>
                        <div>
                            <Button
                                startIcon={<ArrowBackOutlined />}
                                onClick={() => {
                                    navigate('/eventsadmin');
                                }}>zpět</Button>
                        </div>
                        {/* <div style={{ position: 'fixed', top: '32%', left: '1%' }}><Button startIcon={<SaveRounded />} variant='contained' sx={{ height: '80px', width: '148px' }}>ULOŽIT</Button></div> */}
                        <div ><Button onClick={() => { setFirstRound(!firstRound); UpdateRaceState(!firstRound, isPro); }}>Změnit kolo</Button></div>
                        <div ><Button onClick={() => {
                            setQualificationEnding(true);
                            // new ApiBaseClient().GetAuthedClient().createBaseBracketForCategory(Number(params['raceID']),false);
                        }}>Zahájit battle</Button></div>
                        {!together && <div style={{ display: 'flex'}}>
                                <Button onClick={() => { setIsPro(true); UpdateRaceState(firstRound, true); }}>{isPro ? <PlayArrow/> : ''}Pro</Button>
                                <Button onClick={() => { setIsPro(false); UpdateRaceState(firstRound, false); }}>{!isPro ? <PlayArrow/> : ''}Street</Button>
                            </div>}
                       {/*  <FormGroup>
                            <FormControlLabel control={<Checkbox title='PRO/STREET dohromady' checked={together} onChange={(e) => { setTogether(e.target.checked); refreshAttendees(); }}/>} label="PRO/STREET dohromady" />
                        </FormGroup> */}
                    </div>}
                {!props.isJury && <div>
                    <div style={{ display: 'flex', gap: '8px'}}>
                            <Button onClick={() => { setIsPro(true); }}>{isPro ? <PlayArrow/> : ''}Pro</Button>
                            <Button onClick={() => { setIsPro(false); }}>{!isPro ? <PlayArrow/> : ''}Street</Button>
                    </div>
                </div>}
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: orderWidth }}><Typography variant='h6' textAlign='center'>POŘ.</Typography></div>
                <div style={{ minWidth: '60px', marginLeft: '8px' }}><Typography variant='h6' textAlign='right'>BODY</Typography></div>

                <div style={{ minWidth: numberWidth, paddingRight: '10px' }}><Typography variant='h6' textAlign='right'>ČÍSLO</Typography></div>
                <div style={{ minWidth: nameWidth, marginLeft: '12px' }}><Typography variant='h6'>ZÁVODNÍK</Typography></div>
                <div style={{ width: '100px', height: '32px', backgroundColor: 'white' }}><Typography variant='h6' sx={{ width: '100px'}}></Typography></div>
                {categories.map((cat, i) => {
                    return <div key={i} style={{ minWidth: pointsWidth }}><Typography variant='h6' textAlign='left' color='#D82C90' fontWeight='bold'>{cat.name}</Typography></div>;
                })}
            </div>
                {loading && <CircularProgress />}
                {!loading && <div style={{ border: '1px solid #C3C3C3', borderRadius: '4px', marginBottom: '20px', padding: '1px 0px 2px 0px' }}>
                    {attendees.map((att, i) => <div style={{ height: '60px' }}>
                        <QualififyRacer
                        doneOne={att.doneOne}
                        doneTwo={att.doneTwo}
                        isPro={isPro}
                        order={i + 1}
                        onSave={() => { setEditingRacer(undefined); refreshAttendees(isPro) }}
                        onEdit={() => {
                            setEditingRacer(att);
                        }}
                        onPointOpen={(startNumber: number) => { setCurrentRacerStartNumber(startNumber); setRacerPointOpen(true);}}
                        editingRacer={editingRacer}
                        numberWidth={numberWidth}
                        roundOne={firstRound}
                        categories={categories}
                        pointsWidth={pointsWidth}
                        buttonsWidth={100}
                        nameWidth={nameWidth}
                        isJury={props.isJury}
                        racer={att}
                        key={'att' + i} />
                    </div>)}
                </div>}
            {/* {props.isJury && <Button onClick={() => { setFirstRound(!firstRound); UpdateRaceState(!firstRound, isPro); }}>Změnit kolo</Button>}
            {props.isJury && <Button onClick={() => { setIsPro(!isPro); UpdateRaceState(firstRound, !isPro); }}>Pro/street</Button>}
            {props.isJury && <Button onClick={() => {
                setQualificationEnding(true);
                // new ApiBaseClient().GetAuthedClient().createBaseBracketForCategory(Number(params['raceID']),false);
            }}>Zahájit pavouky</Button>}
            {props.isJury && <Button
                variant="contained"
                onClick={() => {
                    navigate('/eventsadmin');
                }}>Seznam závodů</Button>} */}
            {/* {props.isJury &&
                <div>

                    <div style={{ position: 'fixed', top: '60px', left: '32px' }}>
                        <Button
                            startIcon={<ArrowBackOutlined />}
                            onClick={() => {
                                navigate('/eventsadmin');
                            }}>zpět</Button>
                    </div>

                </div>} */}
            {props.isJury && <QualificationRacerPointDialog raceId={Number(params['raceID'])} onChangeRound={() => {
                setFirstRound(!firstRound); UpdateRaceState(!firstRound, isPro);
            }} isOpen={racerPointOpen} attendees={attendees} categories={categories} currentStartNumber={currentRacerStartNumber} roundOne={firstRound} isPro={isPro} onClose={() => {setRacerPointOpen(false); refreshAttendees(isPro); } } onChangeCurrentStartNumber={(startNumber: number) =>  {
                if (startNumber < 1) setCurrentRacerStartNumber(1)
                    else if (startNumber > maxStartNumberAttendee) setCurrentRacerStartNumber(maxStartNumberAttendee)
                    else setCurrentRacerStartNumber(startNumber);
                }
            } />}
        </div>
    )
}
