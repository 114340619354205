import { Button, Dialog, DialogContent, TextField, Typography } from '@mui/material'
import React, { LegacyRef, RefObject, useEffect, useRef, useState } from 'react'
import { Check, Clear } from '@mui/icons-material';
import './DisclaimerText.css';

interface DisclaimerTextProps {
    name: string;
    lastname: string;
    birthdate: string;
    address: string;
    contactPerson: string;
    raceName: string;
    raceDate: string;
}

export default function DisclaimerText(props: DisclaimerTextProps) {
   

    return (
        <div className='WordSection1'>
            <p className="MsoNormal">
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <span style={{marginLeft: 0, marginTop: 0, width: 226, height: 120}}>
                        <img width={151} height={80} src={process.env.PUBLIC_URL + '/image001.jpg'} alt="Logo Driftcorporation" />
                    </span>
                    <div style={{ display: 'block'}}>
                        <b>
                            <span style={{fontFamily: '"Helvetica",sans-serif', display: 'block'}}>
                            DRIFT CORPORATION s.r.o.
                            </span>     
                        </b>
                        <span style={{fontFamily: 'Helvetica, sans-serif', display: 'block', marginTop: '4px'}}>
                            IČO: 21336890
                        </span>
                        
                        <span style={{fontFamily: 'Helvetica, sans-serif', display: 'block', marginTop: '4px'}}>
                            Revoluční 1006/5, 11000 Praha
                        </span>
                    </div>
                    
                </div>
            </p>

            <p className="MsoNormal" style={{textAlign: 'center'}}>
                <span style={{fontSize: '18.0pt', fontWeight: 'bold', lineHeight: '107%', fontFamily: 'Helvetica, sans-serif'}}>
                    Prohlášení účastníka závodu
                </span>
            </p>

            <p className="MsoNormal">
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                   &nbsp;
                </span>
            </p>

            <p className="MsoNormal">
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    Já, níže podepsaný 
                </span>
            </p>

            <p className="MsoNormal">
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    Jméno, příjmení: {props.name} {props.lastname}
                </span>
            </p>

            <p className="MsoNormal">
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    Datum narození: {props.birthdate}</span>
            </p>

            <p className="MsoNormal">
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    Adresa místa trvalého pobytu:  {props.address}</span>
            </p>

            <p className="MsoNormal">
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    &nbsp;
                </span>
            </p>

            <p className="MsoNormal">
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    (dále jen jako „účastník“)
                </span>
            </p>

            <p className="MsoNormal">
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    Kontaktní osoba pro případ nouze : {props.contactPerson}
                </span>
            </p>            

            <p className="MsoNormal">
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    &nbsp;
                </span>
            </p>

            <p className="MsoNormal" style={{textAlign: 'left'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    Toto prohlášení je jednou ze startovních podmínek k účasti v závodech Drift Corporation pořádaný společností Drift Corporation s.r.o., IČO: 21336890, se sídlem Revoluční 1006/5, Staré Město, 110 00 Praha 1, vedená u Městského soudu v&nbsp;Praze C 400344 (dále jen „pořadatel“). Následující prohlášení a účastnické podmínky jsou předmětem platného ujednání mezi níže podepsaným účastníkem a pořadatelem závodu.
                </span>
            </p>

            <p className="MsoNormal" style={{textAlign: 'left'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    Účastníkem závodu je osoba, která se závodů účastní jako řidič soutěžního vozidla přihlášeného k&nbsp;účasti v&nbsp;závodu.
                </span>
            </p>

            <p className="MsoNormal">
                <b>
                    <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                        Shora uvedený účastník závodu prohlašuje a souhlasí, že:
                    </span>
                </b>
            </p>

            <p className="MsoListParagraphCxSpFirst" style={{textAlign: 'left', textIndent: '-18.0pt'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    1.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>se podrobně seznámil s&nbsp;pokyny k&nbsp;registraci, které obdržel v&nbsp;rámci registrace obsahující podmínky účasti v závodu pořádaném pořadatelem DRIFT CORPORATION s.r.o., konaném v&nbsp;termínu {props.raceDate} u {props.raceName} (dále jen jako „závod“), a zavazuje se dodržovat podmínky upravené v&nbsp;Pravidlech Drift Corp., dostupných na driftcorporatioon.cz spolu se stanovenými pravidly závodu a zavazuje se dbát a závazně respektovat organizační, bezpečnostní a jiné pokyny, příkazy nebo doporučení pořadatele a tyto bez výhrad přijmout a respektovat. 
                </span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{textAlign: 'left'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>&nbsp;</span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{textAlign: 'left', textIndent: '-18.0pt'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    2.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>je fyzicky a zdravotně způsobilý se zúčastnit závodu, minimálně v&nbsp;rozsahu být účastníkem provozu na pozemních komunikacích. Účastník prohlašuje, že mu nebrání v&nbsp;účasti žádná zdravotní překážka nebo omezení a dále prohlašuje, 
                </span>
            </p>

            <p className="MsoListParagraphCxSpMiddle">
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>&nbsp;</span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{textAlign: 'left', textIndent: '-18.0pt'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    3.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>že splňuje podmínky pro účast a soutěžní nebo exhibiční jízdu v&nbsp;souladu se zákonem č. 361/2000 Sb., o provozu na pozemních komunikacích, disponuje zkušenostmi, dovednostmi a schopnostmi pro kontrolu nad jedoucím vozidlem v&nbsp;tzv. driftu a je způsobilý pro účast na&nbsp;exhibiční nebo závodní jízdě po závodním okruhu, aby neohrozil ostatní účastníky závodu. 
                </span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{textAlign: 'left'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>&nbsp;</span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{textAlign: 'left'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>&nbsp;</span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{textAlign: 'left', textIndent: '-18.0pt'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    4.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>Účastník se účastní závodu dobrovolně na základě&nbsp;vlastního rozhodnutí a na vlastní nebezpečí a vlastní riziko. Účastník je povinen seznámit se řádně s&nbsp;tratí závodu a počínat si tak, aby neohrozil zdraví nebo majetek ostatních účastníků, pořadatele, diváků, apod. Účastník odpovídá i za případné způsobené škody, které vzniknou pořadateli, majiteli či provozovateli objektu, závodní dráhy či třetím osobám v důsledku jednání nebo chování nebo opomenutí účastníka,
                </span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{textAlign: 'left'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>&nbsp;</span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{textAlign: 'left', textIndent: '-18.0pt'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    5.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>Účastník se výslovně vzdává a není oprávněn požadovat po pořadateli jakékoliv finanční plnění nebo uplatňovat jiné nároky v případě zdravotní újmy nebo jiné škody, kterou účastník utrpí v souvislosti s účastí v&nbsp;závodu nebo v&nbsp;důsledku nekontrolované události (např. střet vozidel dvou nebo více účastníků, náraz do bezpečnostní bariéry apod.),
                    <a></a>
                </span>
            </p>

            <p className="MsoListParagraphCxSpMiddle">
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>&nbsp;</span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{textAlign: 'left', textIndent: '-18.0pt'}}>
                <span style={{fontFamily: 'Helvetica, sans-serif'}}>
                    6.<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>bude bezvýhradně respektovat organizační, bezpečnostní a jiné pokyny, příkazy nebo doporučení pořadatele závodu, nebo toho, kdo je k&nbsp;vydávaní pokynů pořadatelem pověřen, např. traťový komisař. V případě porušení či nerespektování těchto pokynů účastník závodu má pořadatel právo účastníka ze závodu vykázat nebo mu zrušit účast v&nbsp;závodu a to bez náhrady. V&nbsp;případě nerespektování pokynů pořadatele, nebo v&nbsp;případě porušení povinnosti, ke které se účastník zavázal, odpovídá v celém rozsahu za škodu, která v důsledku této skutečnosti vznikne pořadateli a jakékoliv další třetí osobě,
                </span>
            </p>

            <p className="MsoListParagraphCxSpMiddle">
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>&nbsp;</span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{ textAlign: 'left', textIndent: '-18.0pt' }}>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                7.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </span>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                V&nbsp;případě, že bude účastník bude v&nbsp;rámci účasti na závodu, nebo v&nbsp;souvislosti s&nbsp;touto účastí, užívat veřejnou cestu, pozemní komunikaci nebo jinou dopravní plochu, mimo vymezenou trať závodu (autodromu), účastník bude na takových komunikacích dodržovat pravidla silničního provozu dle zákona č. 361/2000 Sb., o provozu na pozemních komunikacích a souvisejících právních předpisů, a zejména si bude počínat tak, aby se předešlo k&nbsp;jakékoliv újmě nebo škodě na zdraví, majetku nebo životním prostředí,
                </span>
            </p>

            <p className="MsoListParagraphCxSpMiddle">
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>&nbsp;</span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{ textAlign: 'left', textIndent: '-18.0pt' }}>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                8.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </span>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                se zavazuje dodržovat etická, morální pravidla a veřejný pořádek i mimo termíny nebo akce pořádané pořadatelem, neohrožovat pověst svoji, ostatních účastníků nebo pořadatele,
                </span>
            </p>

            <p className="MsoListParagraphCxSpMiddle">
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>&nbsp;</span>
            </p>

            <p className="MsoListParagraphCxSpMiddle" style={{ textAlign: 'left', textIndent: '-18.0pt' }}>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                9.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
                </span>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                pořadatel je oprávněn zachytit podobiznu nebo majetek účastníka závodu. Pořadatel je oprávněn zpracovat nebo zveřejnit podobiznu nebo jiné filmové nebo fotografické záznamy obsahující&nbsp;podobiznu účastníka, a to pro reklamní a propagační účely DRIFT CORPORATION s.r.o. nebo užít a zveřejnit jméno nebo příjmení účastníka nebo jiné údaje bezprostředně odkazující na osobu účastníka, a to na webových stránkách pořadatele, sociálních sítích, zejména Facebook, Instagram apod. bez omezení a v&nbsp;přiměřeném rozsahu. Souhlas je neodvolatelný a v&nbsp;souvislosti s&nbsp;ním nemá účastník nárok na žádné protiplnění.
                </span>
            </p>

            <p className="MsoListParagraphCxSpMiddle">
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>&nbsp;</span>
            </p>

            <p className="MsoListParagraphCxSpLast" style={{ textAlign: 'left', textIndent: '-18.0pt' }}>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                10.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
                </span>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                se zavazuje zúčastnit tzv. rozpravy, bezpečnostní a organizační školení všech účastníků s&nbsp;pořadatelem a osobami pověřenými bezpečnostní a organizací závodu, které předchází zahájení závodu.
                </span>
            </p>

            <p className="MsoNormal">
                <b>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                    Shora uvedený účastník závodu bere na vědomí, že:
                </span>
                </b>
            </p>

            <p className="MsoNormal" style={{ marginLeft: '35.4pt', textAlign: 'left' }}>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                1. pořadatelem nebylo uzavřeno žádné pojištění na náhradu škody způsobené účastníkovi v souvislosti s účastí v závodu.<b> Pořadatel doporučuje uzavřít účastníkovi pojištění</b>.
                </span>
            </p>

            <p className="MsoNormal" style={{ marginLeft: '35.4pt', textAlign: 'left' }}>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                2. všichni účastníci mají povinnost účastnit se závodu ve vybavení, tzn. s homologovanou závodní helmou, nehořlavém oblečení, rukavicemi a botami.
                </span>
            </p>

            <p className="MsoNormal" style={{ marginLeft: '35.4pt', textAlign: 'left' }}>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                3. <a>se nesmí účastnit závodu pod vlivem alkoholu nebo jiné návykové látky</a>. Pořadatel je oprávněn provádět kontrolu a případně vykázat účastníka z&nbsp;trati nebo ukončit účast v&nbsp;závodu.
                </span>
            </p>

            <p className="MsoNormal" style={{ marginLeft: '35.4pt', textAlign: 'left' }}>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                4. účastník prohlašuje, že motorové vozidlo, se kterým se bude závodu účastnit, je k&nbsp;účasti v závodu technicky způsobilé a bezpečné, odpovídá požadavkům pořadatele, a není ve stavu způsobilém ohrozit ostatní účastníky. Účastník se zavazuje každý defekt nebo technickou závadu vozidla okamžitě hlásit pořadateli, který zváží, zda vozidlo smí být nadále použito v&nbsp;programu nebo s&nbsp;ním nesmí nadále účastník pokračovat v&nbsp;programu.
                </span>
            </p>

            <p className="MsoNormal">
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>&nbsp;</span>
            </p>

            <p className="MsoNormal" style={{ textAlign: 'justify' }}>
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
                Vyjádřením souhlasu s tímto prohlášením účastník potvrzuje, že informace a prohlášení poskytnuté účastníkem v tomto Prohlášení účastníka závodu, jsou pravdivé a úplné.
                </span>
            </p>

            <p className="MsoNormal">
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>&nbsp;</span>
            </p>

            <p className="MsoNormal">
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>V Liberci dne 3.8.2024</span>
            </p>

            <p className="MsoNormal">
                <span style={{ fontFamily: 'Helvetica, sans-serif' }}>&nbsp;</span>
            </p>

        </div>
    )
}
