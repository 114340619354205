import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes, Router } from 'react-router';
import { ThemeProvider, useTheme } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { registerLicense } from '@syncfusion/ej2-base';
import RegisterToRace from './PublicScenes/RegisterToRace';
import Blank from './PublicScenes/Blank';
import RacesList from './PublicScenes/RacesList';
import RaceDetail from './RacesAdministration/RaceDetail';
import RacesListAdministration from './RacesAdministration/RacesListAdministration';
import SuccessPayment from './PublicScenes/SuccessPayment';
import UnsuccessPayment from './PublicScenes/UnsuccessPayment';
import RaceAttendees from './RacesAdministration/RaceAttendees';
import RacePublicDetail from './PublicScenes/RacePublicDetail';
import CreateDriftTheme from './Shared/CreateDriftTheme';
import Login from './RacesAdministration/Login';
import ProtectedRoute from './Shared/ProtectedPath';
import SignalTest from './CurrentRace/SignalTest';
import StartCurrentRace from './CurrentRace/StartCurrentRace';
import AttendeeSubmit from './CurrentRace/AttendeeSubmit';
import Qualification from './CurrentRace/Qualification';
import RaceCategoriesAdministration from './RacesAdministration/RaceCategoriesAdministration';
import { SingleElimination } from './CurrentRace/Bracket';
import RaceAttendeeDetail from './RacesAdministration/RaceAttendeeDetail';
import RaceAttendeesPublic from './PublicScenes/RaceAttendeesPublic';
import { SingleEliminationProjector } from './CurrentRace/BracketProjector';

function App() {
  const theme = CreateDriftTheme();
  registerLicense('Mgo+DSMBaFt/QHRqVVhlWFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTnxTdkBhWH1ecHJUQQ==;Mgo+DSMBPh8sVXJ0S0J+XE9BclRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TcUdjWXpccXZVRmdaVg==;ORg4AjUWIQA/Gnt2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkJhWX9bcnVXRmBVUUM=;MjU5MDM2NEAzMjMwMmUzNDJlMzBad2tyZ1oxcElBYWFmN2tkdUt2aHBYTEdEaDhVOWd3TDFKMWR2QmhTcGI0PQ==;MjU5MDM2NUAzMjMwMmUzNDJlMzBZbmd4ZXhIa2RhMFZrWVIxRytobUZvV1Q2WTJmdUhRS2hKOGI0T1NGNVQ0PQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFpGVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVkW39edXZURGBdVkF1;MjU5MDM2N0AzMjMwMmUzNDJlMzBRZVJhSWltazF3RGN0ZXZXYis1L0JRU0RBTVNVcHU1Vnp2bDRBUHZvQVBrPQ==;MjU5MDM2OEAzMjMwMmUzNDJlMzBnMzlWNElFc2l6VFZMR1lMU084dzNwTGkzYmZLOHdkVkdoNFhiSFR4YWRnPQ==;Mgo+DSMBMAY9C3t2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkJhWX9bcnVXRmJUVUc=;MjU5MDM3MEAzMjMwMmUzNDJlMzBjWFc5eEtaOS8zalBMRWk5ZVZmdDJPSG5nbjhGdFo5VU1PeU1Fc2R2UFJnPQ==;MjU5MDM3MUAzMjMwMmUzNDJlMzBENlVteHhEV0w0SnIvWURTaU15ZHNiTG9XanNRSUh3bUtmWXNSdEhTd3JJPQ==;MjU5MDM3MkAzMjMwMmUzNDJlMzBRZVJhSWltazF3RGN0ZXZXYis1L0JRU0RBTVNVcHU1Vnp2bDRBUHZvQVBrPQ==');
  // const router = createBrowserRouter
  return (
    <div style={{ fontFamily: 'Big Shoulders Text', padding: '40px 180px 40px 180px' }}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/register" element={<RegisterToRace />} /> */}
            <Route path="/register" element={<RegisterToRace />} />
            <Route path="/register/:id" element={<RegisterToRace />} />
            <Route path="/racedetail" element={<RaceDetail />} />
            <Route path="/events" element={<RacesList />} />
            <Route path="/eventsadmin" element={<ProtectedRoute><RacesListAdministration /></ProtectedRoute>} />
            <Route path="/success_payment/:id/:user" element={<SuccessPayment />} />
            <Route path="/unsuccess_payment" element={<UnsuccessPayment />} />
            <Route path="/attendees_public/:id" element={<RaceAttendeesPublic />} />
            <Route path="/attendees/:id" element={<ProtectedRoute><RaceAttendees /></ProtectedRoute>} />
            <Route path="/racestart/:id" element={<ProtectedRoute><StartCurrentRace /></ProtectedRoute>} />
            <Route path="/attendeesubmit/:raceid/:attendeeid" element={<ProtectedRoute><AttendeeSubmit /></ProtectedRoute>} />
            <Route path="/event/:id" element={<RacePublicDetail />} />
            <Route path="/signaltest" element={<SignalTest />} />
            <Route path="/bracket/:raceID" element={<SingleElimination isJury />} />
            <Route path="/bracket_public/:raceID" element={<SingleElimination />} />
            <Route path="/bracket_projector/:raceID" element={<SingleEliminationProjector />} />
            <Route path="/qualification/:raceID" element={<Qualification isJury />} />
            <Route path="/qualification_public/:raceID" element={<Qualification isJury={false} />} />
            <Route path="/categories/:raceID" element={<RaceCategoriesAdministration />} />
            <Route path="/" element={<Login />} />
            <Route path="/raceattendee/:id" element={<RaceAttendeeDetail />} />
            <Route path="/raceattendee/:id/:race" element={<RaceAttendeeDetail />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
