import { TextField, Autocomplete, Button, FormControlLabel, Checkbox, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RaceWhisperDTO, SignUpToRaceRequest } from '../Api/ApiServerDC';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { useParams } from 'react-router';
import SuccessRegistrationDialogNoPay from './SuccessRegistrationDialogNoPay';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function RegisterToRace() {
    const params = useParams();
    useEffect(() => {
        new ApiBaseClient().GetClientToAuth().eventsPublicWhisper().then((e) => {
            if (e.races)
                setRaces(e.races);


        });
    }, []);

    const theme = useTheme();
    const biggerThan700 = useMediaQuery(theme.breakpoints.up(700));
    const [races, setRaces] = React.useState<RaceWhisperDTO[]>([]);
    const [selectedRace, setSelectedRace] = React.useState<RaceWhisperDTO | null>(null);
    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");
    const [address, setAddress] = React.useState<string>("");
    const [bornDate, setBornDate] = React.useState<Date | null>();
    const [email, setEmail] = React.useState<string>("");
    const [phone, setPhone] = React.useState<string>("");
    const [car, setCar] = React.useState<string>("");
    const [freeCode, setFreeCode] = React.useState<string>("");
    const [streetCategory, setStreetCategory] = React.useState(true);
    const [proCategory, setProCategory] = React.useState(false);
    const [chooseRace, setChooseRace] = React.useState(false);
    const [successWithoutPaying, setSuccessWithoutPaying] = useState(false);
    useEffect(() => {
        if (params && params.id && races.length > 0) {
            setSelectedRace(races.find(x => x.id === Number(params.id))!);
            setChooseRace(true);
        }
        else setChooseRace(false);
    }, [races, params]);

    function isValidDate(inputDate: string) {
        return !isNaN(Date.parse(inputDate));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: biggerThan700 ? '' : '-160px', marginRight: biggerThan700 ? '' : '-160px' }}>
            <SuccessRegistrationDialogNoPay isOpen={successWithoutPaying} onClose={() => { setSuccessWithoutPaying(false) }} />
            <Typography variant='h3' color='#D82C90' textAlign='center'>REGISTRACE</Typography>
            <TextField sx={{ margin: '12px 0px' }} label='Jméno' value={firstName} onChange={(e) => { setFirstName(e.currentTarget.value) }} />
            <TextField sx={{ margin: '12px 0px' }} label='Přijmení' value={lastName} onChange={(e) => { setLastName(e.currentTarget.value) }} />

            <TextField  sx={{ margin: '12px 0px' }} label='Adresa' value={address} onChange={(e) => { setAddress(e.currentTarget.value) }} />
            {/* <TextField error={!isValidDate(bornDate)} sx={{ margin: '12px 0px' }} label='Datum narození' value={bornDate} onChange={(e) => { setBornDate(e.currentTarget.value) }} /> */}
            <LocalizationProvider adapterLocale='cs' dateAdapter={AdapterDayjs} >
                <DatePicker sx={{ margin: '12px 0px' }} format="DD.MM.YYYY"
                        label="Datum narození" value={bornDate} onChange={(value) => { setBornDate(value); }} />
            </LocalizationProvider>

            <TextField sx={{ margin: '12px 0px' }} label='Email' value={email} onChange={(e) => { setEmail(e.currentTarget.value) }} />
            <TextField sx={{ margin: '12px 0px' }} label='Telefon' value={phone} onChange={(e) => { setPhone(e.currentTarget.value) }} />
            <Autocomplete
                disabled={chooseRace}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                options={races} value={selectedRace}
                sx={{ margin: '12px 0px' }}
                onChange={(e, v) => { setSelectedRace(v) }}
                renderInput={(e) => { return <TextField {...e} label={'Závod'} /> }}></Autocomplete>
            {(selectedRace && !selectedRace.isMeeting) && <div style={{display:'flex'}}>
                <FormControlLabel
                    value="end"
                    control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={proCategory}
                        onChange={(e, checked) => { setProCategory(checked) }} />}
                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>PRO</Typography>}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value="end"

                    control={<Checkbox sx={{ marginLeft: '0px', marginRight: '-6px' }} checked={streetCategory}
                        onChange={(e, checked) => { setStreetCategory(checked) }} />}
                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>STREET</Typography>}
                    labelPlacement="end"
                />
            </div>}
            <TextField sx={{ margin: '12px 0px' }} label='Auto' value={car} onChange={(e) => { setCar(e.currentTarget.value) }} />
            <TextField sx={{ margin: '12px 0px' }} label='Kód pro registraci zdarma' value={freeCode} onChange={(e) => { setFreeCode(e.currentTarget.value) }} />
            <Button variant='contained' disabled={firstName === "" || lastName === "" || email === "" || phone === "" || selectedRace === null || address === "" || bornDate === null || selectedRace.registrationClosed === true } onClick={() => {
                if (selectedRace === null || selectedRace.registrationClosed === true) return;
                new ApiBaseClient().GetClientToAuth().createPaymentSession(new SignUpToRaceRequest({
                    car: car,
                    email: email,
                    firstName: firstName,
                    surname: lastName,
                    address: address,
                    bornDate: bornDate === null ? undefined : bornDate,
                    phone: phone,
                    raceId: selectedRace!.id,
                    pro: proCategory,
                    street: streetCategory,
                    freeCode: freeCode
                })).then((url) => {
                    if (url.mustPay === true && url.url)
                        window.location.href = url.url;
                    else setSuccessWithoutPaying(true);
                })
            }} sx={{ /* height: '80px', */ marginTop: '16px' }}><Typography variant='h4'>{selectedRace && selectedRace.registrationClosed === true ? 'Registrace uzavřeny' : 'Registrovat'}</Typography></Button>
        </div>
    )
}
