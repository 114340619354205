import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { ValidateAttendeeByPaymentRequest } from '../Api/ApiServerDC';
import delay from '../Shared/delay';
import { CircularProgress, Typography } from '@mui/material';

export default function SuccessPayment() {
    const [validating, setValidating] = React.useState(false);
    const [validated, setValidated] = React.useState(false);
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (params && params["id"] && params["user"]) {
            console.log(params["id"]);
            console.log(params["user"]);
            setValidating(true);
            new ApiBaseClient().GetClientToAuth().validateAttendeeByPayment(new ValidateAttendeeByPaymentRequest({ attendeeId: Number(params['user']), paymentId: params["id"] })).then(() => {
                setValidating(false);
                setValidated(true);
                delay(5000).then(() => {

                    window.location.href = "https://www.driftcorporation.cz";
                });
            }).catch(()=>{
                navigate('/unsuccess_payment')
            });
        }

    }, []);
    return (
        <div>
            {validated && <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{ border: '1px solid #D82C90', borderRadius: '8px', padding: '40px', maxWidth: '600px' }}>
                        <div>
                            <Typography variant='h6' textAlign='center'>
                                Potvrzení o  platbě a další informace k závodu najdeš ve svém emailu.
                                
                                
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src='https://i.gifer.com/7efs.gif' style={{ width: '160px' }}></img>
                            
                        </div>
                        <Typography variant='body1' textAlign='center'>nyní tě přesměrujeme na hlavní stránku</Typography>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            
                        </div>
                    </div>
                </div>}
            {validating && <div>
                <CircularProgress />Ověřuji
            </div>}
        </div>
    )
}
