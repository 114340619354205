import React, { useCallback, useEffect, useState } from 'react'
import { CategoryForEventDTO, RaceAttendeeDTO, RacerPointInQualifyDTO, SaveRacerPointsInQualifyRequest } from '../Api/ApiServerDC';
import { IconButton, TextField, Typography, useTheme } from '@mui/material';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { Check, Close } from '@mui/icons-material';
import CreateDriftTheme from '../Shared/CreateDriftTheme';


interface QualififyRacerProps {
    categories: CategoryForEventDTO[];
    racer: RaceAttendeeDTO;
    editingRacer: RaceAttendeeDTO | undefined;
    isJury: boolean;
    isPro: boolean;
    nameWidth: number;
    buttonsWidth: number;
    numberWidth: number;
    pointsWidth: number;
    roundOne: boolean;
    doneOne: boolean;
    doneTwo: boolean;
    order: number;
    onSave: () => void;
    onEdit: () => void;
    onPointOpen: (startNumber: number) => void;
}
export default function QualififyRacer(props: QualififyRacerProps) {
    const { racer, categories, isJury, roundOne, doneOne, doneTwo, isPro, onSave, onEdit, order, editingRacer, onPointOpen } = props;
    const [points, setPoints] = useState<RacerPointInQualifyDTO[]>([]);
    const [changed, setChanged] = useState(false);
    const refreshRacer = async () => {
        if (racer.id)
            await new ApiBaseClient().GetClientToAuth().getRacerPointInQualify(racer.id, isPro).then((points) => {
                if (points.pointsInCategories) {
                    var currentPoints: RacerPointInQualifyDTO[] = [];
                    for (let category of categories) {
                        let existingPoints = points.pointsInCategories.find(x => x.categoryId === category.id);
                        if (existingPoints !== undefined) {
                            currentPoints.push(existingPoints);
                        }
                        else currentPoints.push(new RacerPointInQualifyDTO({ categoryId: category.id, id: -1, pointsOne: 0, pointsTwo: 0, categoryName: category.name, maximalPoints: category.maximalPoints}));
                    }
                    setPoints(currentPoints);
                    setChanged(false);
                }

                // if (events.races)
                //     setRaces(events.races);
            });
    }
    useEffect(() => {
        refreshRacer();
        
    }, [racer, categories, isJury, isPro]);

    useEffect(() => {
        if (changed) {
            onEdit();
        }
    }, [points, changed]);

    const handlePointsChange = (categoryId: number, newValue: number, pointsOne: boolean) => {
        //onEdit();
        const updatedPoints = points.map(point => {
            if (point.categoryId === categoryId) {
                setChanged(true);
                if (pointsOne)
                    return new RacerPointInQualifyDTO({ categoryId: point.categoryId, id: point.id, pointsOne: newValue, pointsTwo: point.pointsTwo, isChanged: true, categoryName: point.categoryName, maximalPoints: point.maximalPoints});
                else return new RacerPointInQualifyDTO({ categoryId: point.categoryId, id: point.id, pointsOne: point.pointsOne, pointsTwo: newValue, isChanged: true, categoryName: point.categoryName, maximalPoints: point.maximalPoints});
            }
            return point;
        });
        setPoints(updatedPoints);

        /* if (racer.id)
            onChange(racer.id, isPro, updatedPoints); */
    };
    const saveChanges = (attendeeId: number) => {
        new ApiBaseClient().GetClientToAuth().saveRacerPointsInQualify(new SaveRacerPointsInQualifyRequest({ attendeeId: attendeeId, isPro: isPro, points: points })).then((res) => {
            refreshRacer().then(() => onSave());
        });

    }
    const theme = useTheme();

    const getBackgroundColor = useCallback(() => {
        if (roundOne && doneOne) return 'silver';
        else if (!roundOne && doneTwo) return 'silver';
        else return 'white';
    }, [roundOne,doneOne, doneTwo, theme.palette.primary.main, theme.palette.success.main, theme.palette.background.default]);

    return (
        <div style={{ display: 'flex', whiteSpace: 'nowrap', background: getBackgroundColor() }}>
            
            <div style={{ backgroundColor: '#F5F5F5', minWidth: '50px', alignContent: 'center', padding: '25px 0px 8px 8px' }}>
                <Typography variant='body1' textAlign='left' pr='12px'>{order}.</Typography>
            </div>
            <div style={{ backgroundColor: '#F5F5F5', minWidth: '60px', alignContent: 'center', padding: '25px 0px 8px 8px' }}>
                <Typography variant='body1' textAlign='right' pr='12px'>{racer.totalPoints}</Typography>
            </div>
            <div style={{ minWidth: props.numberWidth, textAlign: 'right', alignSelf: 'center', paddingRight: '10px' }}>
                <Typography variant='body1'>{racer.startNumber}</Typography>
            </div>
            <div style={{ minWidth: props.nameWidth, maxWidth: props.nameWidth, textAlign: 'left', alignSelf: 'center', cursor: isJury ? 'pointer' : 'auto'}} onClick={() => {if (isJury) onPointOpen(racer.startNumber)}}>
                <Typography variant='body1'>{racer.firstName} {racer.lastName}</Typography>
            </div>

            {/* {isJury && editingRacer === racer && <div style={{ width: props.buttonsWidth, position: 'relative', display: 'flex' }}>
                {racer.id &&
                    <IconButton size='small' color={changed ? 'success' : 'default'} disabled={!changed} onClick={() => { saveChanges(racer.id!); }}>
                        <Check sx={{ width: '28px' }} /></IconButton>}
                <IconButton color={changed ? 'error' : 'default'} disabled={!changed} onClick={() => { refreshRacer(); onSave() }}><Close sx={{ width: '28px' }} /></IconButton>
            </div>}
            {isJury && editingRacer !== racer && <div style={{ width: props.buttonsWidth, background: 'white' }}>
            </div>} */}
            <div style={{ width: props.buttonsWidth, position: 'relative', display: 'flex', visibility: isJury && editingRacer === racer ? 'visible' : 'hidden' }}>
                {/* {racer.id &&
                    <IconButton size='small' color={changed ? 'success' : 'default'} disabled={!changed} onClick={() => { saveChanges(racer.id!); }}>
                        <Check sx={{ width: '28px' }} /></IconButton>}
                <IconButton color={changed ? 'error' : 'default'} disabled={!changed} onClick={() => { refreshRacer(); onSave() }}><Close sx={{ width: '28px' }} /></IconButton> */}
            </div>

            {points.map((cat) => {
                return <div style={{ display: 'flex', width: props.pointsWidth }}>

                    {isJury && roundOne && cat.categoryId !== undefined &&
                        <div style={{ display: 'flex' }}>
                            <TextField disabled={true/* editingRacer && editingRacer !== racer */} type='number' onFocus={(e) => { e.target.select(); }} variant='filled' sx={{ width: '68px', height: '55px', backgroundColor: '#D82C90' }} inputProps={{ min: 0, style: { textAlign: 'right', color: 'white', fontWeight: 'bold' } }} value={cat.pointsOne} onChange={(e) => { handlePointsChange(cat.categoryId!, Number(e.target.value), true);}} onWheel={(e) => e.preventDefault()}/>
                            <div style={{ backgroundColor: '#F5F5F5', width: '60px', alignContent: 'center', padding: '25px 0px 8px 8px' }}>
                                <Typography variant='body1' fontSize='16px' >{cat.pointsTwo?.toFixed(0)}</Typography>
                            </div>
                        </div>}
                    {isJury && !roundOne && cat.categoryId !== undefined &&
                        <div style={{ display: 'flex' }}>
                            <div style={{ backgroundColor: '#F5F5F5', width: '60px', alignContent: 'center', padding: '25px 0px 8px 8px' }}>
                                <Typography variant='body1' fontSize='16px' >{cat.pointsOne?.toFixed(0)}</Typography>
                            </div>
                            <TextField disabled={true/* editingRacer && editingRacer !== racer */} type='number'  onFocus={(e) => { e.target.select(); }} variant='filled' sx={{ width: '68px', height: '55px', backgroundColor: '#D82C90' }} inputProps={{ min: 0, style: { textAlign: 'right', color: 'white', fontWeight: 'bold' } }} value={cat.pointsTwo} onChange={(e) => handlePointsChange(cat.categoryId!, Number(e.target.value), false)} />
                        </div>}

                    {!isJury &&
                        <div style={{ backgroundColor: roundOne ? theme.palette.primary.main : theme.palette.background.default, width: '60px', alignContent: 'center', padding: '25px 0px 8px 8px' }}><Typography variant='body1' fontSize='16px' sx={{ color: roundOne ? 'white' : 'black', fontWeight: roundOne ? 'bold' : 'normal' }} >{cat.pointsOne?.toFixed(0)} </Typography></div>
                    }

                    {!isJury &&
                        <div style={{ backgroundColor: !roundOne ? theme.palette.primary.main : theme.palette.background.default, width: '60px', alignContent: 'center', padding: '25px 0px 8px 8px' }}><Typography variant='body1' fontSize='16px' sx={{ color: !roundOne ? 'white' : 'black', fontWeight: !roundOne ? 'bold' : 'normal' }}>{cat.pointsTwo?.toFixed(0)} </Typography></div>
                    }

                </div>
            })
            }


        </div >
    )
}
