import React, { useEffect } from 'react'
import { useParams } from 'react-router';

export default function AttendeeSubmit() {
    const params = useParams();
    useEffect(() => {

    }, []);
    return (
        <div>
            <div>Souhlas s pravidly</div>
            <div>tohle jsou pravidla</div>
        </div>
    )
}
