import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { RaceDTO } from '../Api/ApiServerDC';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
export default function RacePublicDetail() {
    const navigate = useNavigate();
    const params = useParams();
    const [race, setRace] = React.useState<RaceDTO | null>(null);
    const [raceRemaining, setRaceRemaining] = React.useState<string>();
    
    useEffect(() => {
        if (params && params["id"]) {
            new ApiBaseClient().GetClientToAuth().raceDetail(Number(params["id"])).then((res) => {
                if (res.race)
                    setRace(res.race);
                new ApiBaseClient().GetClientToAuth().getRemainingString(Number(params["id"])).then((ress) => {
                    setRaceRemaining(ress.remaining);
                });

            });            
        }
    }, []);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAUicHcR0cgVPe3nY0bjbrUHMlwk3EVYPU",
        libraries: ["places"]
    });
    function convertGPSStringToCoordinates(gpsString: string): any {
        // Define a regular expression pattern to match latitude and longitude in a GPS string
        const regexPattern = /(\d+\.\d+),\s*(\d+\.\d+)/;
        const match = gpsString.match(regexPattern);

        if (match) {
            console.log(match);
            const lat = Number(match[1]);
            const lng = Number(match[2]);
            var q = ({ lat: lat, lng: lng });
            return q;
        }

        return null; // Return null if the GPS string doesn't match the expected format
    }
    const theme = useTheme();
    const biggerThan700 = useMediaQuery(theme.breakpoints.up(700));
    const mapContainerStyle = { width: '100%', height: biggerThan700? '400px':'200px' };
    const getTimeString = () => {
        //if (race?.dateStart?.toLocaleDateString('cs') === race?.dateEnd?.toLocaleDateString('cs'))
            return race?.dateStart?.toLocaleDateString('cs') + ' ' + race?.dateStart?.toLocaleTimeString('cs', { hour: '2-digit', minute: '2-digit' }) + ' až ' + race?.dateEnd?.toLocaleDateString('cs') + ' ' + race?.dateEnd?.toLocaleTimeString('cs', { hour: '2-digit', minute: '2-digit' });
        //else return race?.dateStart?.toLocaleDateString('cs') + ' ' + race?.dateStart?.toLocaleTimeString('cs', { hour: '2-digit', minute: '2-digit' }) + ' až ' + race?.dateEnd?.toLocaleTimeString('cs', { hour: '2-digit', minute: '2-digit' });
        //         (race?.dateStart?.toLocaleDateString('cs')
        //     } { race?.dateStart?.toLocaleTimeString('cs', { hour: '2-digit', minute: '2-digit' })
        // } - { race?.dateEnd?.toLocaleDateString('cs') } {
        //     race?.dateEnd?.toLocaleTimeString('cs', { hour: '2-digit', minute: '2-digit' })
    }
    // const googleApiKey = "AIzaSyAUicHcR0cgVPe3nY0bjbrUHMlwk3EVYPU";
    return (
        <div style={{ margin: biggerThan700? '' : '0px -160px' }}>
            <img className='image-event-detail' src={process.env.REACT_APP_API_URL + '/RaceAnonymous/getRacePhoto/' + params["id"]}></img>
            <div style={{ textAlign: 'left', fontSize: '75px', fontWeight: 'bold', margin: '20px 0px' }}>{race?.name}</div>
            <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'montserrat', fontSize: '20px' }}>
                <div style={{ marginBottom: '16px', fontSize: '36px' }}><Typography variant='body1'>{race?.firstText}</Typography></div>
                <div style={{ display: 'flex' }}><div style={{ fontWeight: 'bold' }}>KDY: </div><div style={{ marginLeft: '8px' }}>{getTimeString()}</div></div>
                <div style={{ display: 'flex' }}><div style={{ fontWeight: 'bold' }}>KDE: </div><div style={{ marginLeft: '8px' }}>{race?.place}</div></div>
                <div style={{ display: 'flex' }}><div style={{ fontWeight: 'bold' }}>KAPACITA: </div><div style={{ marginLeft: '8px', marginRight: '8px' }}>{race?.capacity}</div><div>jezdců</div></div>
                <div style={{ display: 'flex' }}><div style={{ fontWeight: 'bold' }}>CENA: </div><div style={{ marginLeft: '8px', marginRight: '8px' }}>{race?.registrationFee}</div><div>,- Kč</div></div>



               {/*  <div style={{ marginTop: '16px' }}><Typography variant='body1'>{race?.secondText}</Typography></div> */}
                {race && race.secondText && <div dangerouslySetInnerHTML={{ __html: race!.secondText! }}></div>}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {params && params.id && <Button disabled={!race?.isUpcoming || race?.registrationClosed} onClick={() => { navigate('/register/' + params.id) }} variant='contained' style={{ alignSelf: 'center', margin: '20px', minWidth: '100%', fontSize: '36px', minHeight: '100px', maxWidth: '300px' }}>{(race && race.registrationClosed ===  true) ? 'Registrace uzavřeny' : 'Registruj se!'}</Button>}
                
                {/*  <div style={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', fontSize: '24px'}}>
                    {'Zbývá míst: ' + raceRemaining}
                </div> */}
                {race && race.formatedText && <div style={{ fontFamily: 'montserrat', fontSize: '20px'}} dangerouslySetInnerHTML={{ __html: race!.formatedText! }}></div>}
            </div>
            {race && race.gps !== undefined && race.gps !== null && isLoaded && 
                <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={convertGPSStringToCoordinates(race.gps)}
                zoom={15}

                
            >
                <MarkerF position={convertGPSStringToCoordinates(race.gps)} />
            </GoogleMap>
            }
        </div >
    )
}
