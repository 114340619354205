import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

export interface UndoBracketDialogProps {
    onAgree: () => void;
    onClose: () => void;
    open: boolean;
}
export default function UndoBracketDialog(props: UndoBracketDialogProps, ref: any) {
    const { onAgree, onClose, open } = props;
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={() => { handleClose() }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Opravdu vrátit match?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {"Opravdu vrátit match?"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { handleClose() }}>{'NE'}</Button>
                <Button onClick={() => { onAgree() }} autoFocus>
                    {'ANO'}
                </Button>
            </DialogActions>
        </Dialog>);
}