import { Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CategoryDTO, CreateOrUpdateRaceCategoryRequest, FileParameter, RaceAttendeeDTO } from '../Api/ApiServerDC';
import { ApiBaseClient } from '../Api/ApiBaseClient';

interface CategoryDialogProps {
    isOpen: boolean;
    raceId: number | undefined;
    category: CategoryDTO | undefined;
    onClose: (refresh: boolean) => void;
}

export default function CategoryDialog(props: CategoryDialogProps) {
    const [saving, setSaving] = useState<boolean>(false);
    const [categoryLocal, setCategoryLocal] = useState<Partial<CategoryDTO>>();

    const { isOpen, onClose, category, raceId } = props;
    useEffect(() => {
        setCategoryLocal(category);
    }, [category]);

    const saveCategory = (e: any) => {
        if (categoryLocal)
        {
            setSaving(true);
            new ApiBaseClient().GetAuthedClient().createOrUpdateRaceCategory(new CreateOrUpdateRaceCategoryRequest({raceId: raceId, category: new CategoryDTO({ id: categoryLocal.id === undefined ? 0 : categoryLocal.id, name: categoryLocal.name, isPro: categoryLocal.isPro, isStreet: categoryLocal.isStreet, maximalPoints: categoryLocal.maximalPoints })})).then((res) => { 
                if (res.ok){
                    onClose(true);
                }
                else setSaving(false);
            });
        }
    }

    if (categoryLocal)
        return (
            <Dialog maxWidth='sm' sx={{ minHeight: '300px', margin: 'auto', width: '210mm', marginTop: '8px', marginBottom: '8px' }} onClose={() => onClose(false)} open={isOpen}>
                <DialogTitle>
                    {categoryLocal.id ? "Editace kategorie" : "Nová kategorie"}
                </DialogTitle>
                <DialogContent sx={{ background: 'white' }}>
                    <div style={{ margin: '16px'}}>
                        <div>                        
                            <TextField sx={{ margin: '12px 0px' }} label='Jméno' value={categoryLocal.name} onChange={(e) => { setCategoryLocal({...categoryLocal, name: e.currentTarget.value}) }} />
                        </div>
                        <div>
                            <TextField type='number' variant='filled' value={categoryLocal.maximalPoints} onChange={(e) => { setCategoryLocal({...categoryLocal, maximalPoints: Number(e.target.value)})}} onWheel={(e) => e.preventDefault()}/>
                        </div>
                        
                        <Button disabled={categoryLocal.name === undefined || categoryLocal.name.trim().length === 0} onClick={(e) => { saveCategory(e) }}>ULOŽIT</Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    else return (<div></div>);
}
