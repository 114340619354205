export function calculateSVGDimensions(
  numOfRows,
  numOfColumns,
  rowHeight: any,
  columnWidth: any,
  canvasPadding: any,
  roundHeader: any,
  currentRound: string = '',
  currentRow: string = '',
  startE: number,
  startF: number,
  usePosition: boolean
) {
  const bracketHeight = numOfRows * rowHeight;
  const bracketWidth = numOfColumns * columnWidth;
  const getRowOffset = (round: number, row: number): number  => {
    if (round < 3)
      return -(((parseInt(currentRow, 10) * rowHeight - canvasPadding * 2) - 150) * Math.pow(2, parseInt(currentRound , 10)));
    else if (round === 3)
        return row === 1 ? -500 : -2000;    
    else if (round === 4)
      return -1150;
      
    return 0;
  }
  const gameHeight =
    bracketHeight +
    canvasPadding * 2 +
    (roundHeader.isShown ? roundHeader.height + roundHeader.marginBottom : 0);
  const gameWidth = bracketWidth + canvasPadding * 2;
  
  const startPosition = usePosition ? [
    currentRound
      ? -(parseInt(currentRound, 10) * columnWidth - canvasPadding * 2)
      : 0,
      !isNaN(parseInt(currentRow))
      ? getRowOffset(parseInt(currentRound, 10), parseInt(currentRow, 10))
      : 0
  ] : [
    !isNaN(startE) ? startE : 0, !isNaN(startF) ? startF : 0
  ];
  console.log(startPosition, currentRound, currentRow, canvasPadding)
  return { gameWidth, gameHeight, startPosition };
}
