import { Button, Dialog, DialogContent, TextField, Typography } from '@mui/material'
import React, { LegacyRef, RefObject, useEffect, useRef, useState } from 'react'
import { FileParameter, RaceAttendeeDTO, RaceAttendeeStartRaceDTO } from '../Api/ApiServerDC';
import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import { Check, Clear } from '@mui/icons-material';
import generatePDF from 'react-to-pdf';
import { ApiBaseClient } from '../Api/ApiBaseClient';
import DisclaimerText from './Components/DisclaimerText';

interface DisclaimerDialogProps {
    isOpen: boolean;
    raceInfo: {name: string, dateRange: string, place: string},
    raceAttendee: RaceAttendeeStartRaceDTO;
    onClose: () => void;
}

export default function DisclaimerDialog(props: DisclaimerDialogProps) {
    const [signatureChanged, setSignatureChanged] = React.useState<boolean>(false);
    const [signFile, setSignFile] = React.useState<FileParameter>();
    const signRef = useRef<SignatureComponent>(null);

    const [printing, setPrinting] = useState<boolean>(false);
    const [signatureString, setSignatureString] = useState<string>();
   /*  const { toPDF, targetRef } = usePDF({method: 'build', filename: 'disclaimer.pdf'}); */

    const { isOpen, onClose, raceInfo, raceAttendee } = props;
    const targetRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (printing){
            //toPDF();
            generatePDF(targetRef, {filename: 'page.pdf', method: 'build'}).then((pdf) => {
                var data = pdf.output('blob');
                let fileParam: FileParameter = { data: data, fileName: 'pdf.pdf' };
                let emptySign: FileParameter = { data: data, fileName: 'empty' };
                new ApiBaseClient().GetAuthedClient().saveRacerDisclaimer(raceAttendee.id!, signFile !== undefined, signFile === undefined ? emptySign : signFile, fileParam).then(() => {
                    setPrinting(false);
                    onClose();});
            });
        }
    }, [printing]);

    
    useEffect(() => {
        getRaceAttendeeSignature();
    }, [raceAttendee]);

    const getRaceAttendeeSignature = () => {
        if (raceAttendee && raceAttendee.id){
            new ApiBaseClient().GetAuthedClient().getRacerSignature(raceAttendee.id).then(res => {
                console.log('sign', res.signature);
                setSignatureString(res.signature);
            });
        }
        else setSignatureString(undefined);
    }

    return (
        <Dialog maxWidth='lg' fullScreen sx={{ minHeight: '300px', margin: 'auto', width: '210mm', marginTop: '8px', marginBottom: '8px' }} onClose={() => onClose()} open={isOpen}>
            <DialogContent sx={{ background: 'white' }}>
                <div style={{ paddingLeft: '20mm', paddingTop: '10mm', paddingRight: '20mm'}} ref={targetRef}>
                    <DisclaimerText name={raceAttendee.firstName} lastname={raceAttendee.lastName} address={raceAttendee.address} birthdate={raceAttendee.bornDate.toLocaleDateString('cs-CZ')} contactPerson={raceAttendee.emergencyContact} raceName={raceInfo.place + " - " + raceInfo.name} raceDate={raceInfo.dateRange}></DisclaimerText>
                    <div>
                        <div style={{ margin: '12px', display: 'flex', flexDirection: 'column', border: '1px solid', borderColor: 'lightgray', padding: '8px', borderRadius: '6px', width: '340px' }}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '13px', margin: '0px 20px' }}>{'Podpis účastníka'}</Typography>
                            {!signatureString && <div>
                                <SignatureComponent change={(e) => {
                                    setSignatureChanged(true);
                                }} style={{
                                    margin: '12px',
                                    backgroundColor: '#F9F9F9'
                                }}
                                    ref={signRef}></SignatureComponent>
                                {!printing && <div style={{ margin: '0px 10px' }}>
                                    <Button sx={{ width: '106px', margin: '4px 12px' }} disabled={!signatureChanged} startIcon={<Clear />} variant='contained' size='small' onClick={(e) => {
                                        signRef.current!.clear();
                                        setSignFile(undefined);
                                        setSignatureChanged(false);
                                    }}>{'Zrušit'}</Button>
                                    <Button sx={{ width: '106px', margin: '4px 12px' }} disabled={!signatureChanged} startIcon={<Check />} color='success' variant='contained' size='small' onClick={(e) => {
                                        setSignatureChanged(false);
                                        var blob = signRef.current!.saveAsBlob();
                                        let fileParameter: FileParameter = { data: blob, fileName: 'sign.png' };
                                        setSignFile(fileParameter);
                                        /* new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).UploadSignature(fileParameter, recordId, isInvestor === undefined ? false : isInvestor).then((e) => {
                                            if (e.ok === true) {
                                                refreshSignatures();
                                                if (onSigned)
                                                    onSigned();
                                            }
                                        }); */
                                    }}>{'Podepsat'}</Button>
                                </div>}
                            </div>}
                            {signatureString && <div>
                                <img src={signatureString} ></img>
                            </div>}
                        </div>
                    </div>
                    
                    {!printing && <Button sx={{ margin: '16px'}} disabled={(!signFile && !signatureString)} onClick={() => {setPrinting(true);}}>ODESLAT</Button>}
                </div>
            </DialogContent>
        </Dialog>
    )
}
