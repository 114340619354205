import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
/* import {
    SingleEliminationBracket,
    DoubleEliminationBracket,
    Match,
    SVGViewer,
    createTheme
} from "react-tournament-brackets"; */
import { BracketMatchDTO, BroadcastCurrentBracketPositionRequest, ChangeRaceStateRequest, RaceState, UndoBracketMatchRequest, UpdateRaceBracketRequest } from "../Api/ApiServerDC";
import { ApiBaseClient } from "../Api/ApiBaseClient";
import { useNavigate, useParams } from "react-router";
import { Button, CircularProgress, IconButton, Typography } from "@mui/material";
import SignalRConnector from "./SinglaRConnector";
import { CarCrashOutlined, CheckOutlined, Close, Maximize, Visibility } from "@mui/icons-material";
import delay from "../Shared/delay";
import { SingleEliminationBracket, SVGViewer, createTheme, Match } from "../TournamentBracket";
import useWindowSize from "../TournamentBracket/src/hooks/use-window-size";
import { useMediaPredicate } from 'react-media-hook';
import UndoBracketDialog from "../Shared/UndoBracketDialog";
import { useLongPress } from 'use-long-press';
import generatePDF from 'react-to-pdf';

interface Participant {
    id: string;
    resultText: string;
    isWinner: boolean;
    status: string;
    name: string;
    picture: string;
}

interface MatchType {
    id: number;
    nextMatchId: number | null;
    tournamentRoundText?: string;
    startTime: string;
    state: string;
    participants: Participant[];
    isThirdPlaceMatch?: boolean;
}
interface ActiveBracketType {
    round: number;
    position: number;
}
function convertToMatchType(dto: BracketMatchDTO): MatchType {
    return {
        id: dto.id ?? 0, // If id is undefined, use a default value (e.g., 0)
        nextMatchId: dto.nextMatchId ?? null, // If nextMatchId is undefined, use null
        tournamentRoundText: dto.tournamentRoundText, // No need for conversion
        startTime: dto.startTime instanceof Date ? dto.startTime.toISOString() : dto.startTime ?? "", // Convert Date to string, handle null/undefined
        state: dto.state ?? "", // If state is undefined, use an empty string
        isThirdPlaceMatch: dto.isThirdPlaceMatch ?? false, // If isThirdPlaceMatch is undefined, use false
        participants: dto.participants?.map(participant => ({
            id: participant.id!.toString(), // Convert number to string
            resultText: participant.resultText ?? "", // If resultText is undefined, use an empty string
            isWinner: participant.isWinner ?? false, // If isWinner is undefined, use false
            status: participant.status ?? "", // If status is undefined, use an empty string
            name: participant.name ?? "", // If name is undefined, use an empty string
            picture: participant.picture ?? "" // If picture is undefined, use an empty string
        })) ?? [] // If participants is undefined, use an empty array
    };
}
interface Props {
    isJury?: boolean;
}
// export default function Bracket(props: Props) {
//     return <SingleElimination isJury={props.isJury} />;
// }

export function SingleElimination(props: Props) {
    const params = useParams();
    const [width, height] = useWindowSize();

    const finalWidth = Math.max(width - 40, 200);
    const finalHeight = Math.max(height - 40, 200);

    useEffect(() => {
        console.log('velikosti', finalWidth, finalHeight, window.devicePixelRatio);
    }, [width, height]);

    useEffect(() => {
        refresh({ round: 0, position: 1 });
    }, []);

    const [isPro, setIsPro] = useState(true);
    const [undoMatchId, setUndoMatchId] = useState<number | undefined>();
    const [serverMatches, setServerMatches] = useState<MatchType[]>([]);
    const [activeBracket, setActiveBracket] = useState<ActiveBracketType>();
    const [loading, setLoading] = useState<boolean>(true);
    //const [all, setAll] = useState<{matches: MatchType[], position: ActiveBracketType}>({matches: [], position: {round: 0, position: 1}});

    const [matches, setMatches] = useState<MatchType[]>([]);
    const [position, setPosition] = useState<ActiveBracketType>({ round: 0, position: 1 })

    const navigate = useNavigate();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    // const undo = useCallback(() => {
    //     new ApiBaseClient().GetAuthedClient().undoBracketMatch(new UndoBracketMatchRequest({ raceId: Number(params['raceID']), isPro: isPro === true }));
    // }, [isPro, params]);
    function undo(matchId: number) {
        setUndoMatchId(undefined);
        new ApiBaseClient().GetAuthedClient().undoBracketMatch(new UndoBracketMatchRequest({ matchId: matchId, isPro: isPro === true })).then(p => {});
    }
    function sendFocusMatch(matchId: number) {
        console.log('send focus', matchId);
        new ApiBaseClient().GetAuthedClient().broadcastCurrentBracketPosition(new BroadcastCurrentBracketPositionRequest({ matchId: matchId, isPro: isPro === true })).then(p => {
            console.log('sent');
        });
    }
    const signalUpdateState = useCallback((params: any) => {
        if (!props.isJury){
            if ((params as RaceState) === RaceState.ProFinal) {
                setIsPro(true);
            }
            else if ((params as RaceState) === RaceState.StreetFinal) {
                setIsPro(false);
            }

        }

        console.log(params);

    }, [isPro]);
    const refresh = useCallback((pos: ActiveBracketType | undefined) => {
        console.log(isPro);

        if (positionLocal.current[0] === 0 || positionLocal.current[1] === 0)
            setLoading(true);
        new ApiBaseClient().GetAuthedClient().getBracketForCurrentRace(Number(params['raceID']), isPro).then((races) => {
           /*  if (races.matches)
                setServerMatches(races.matches.map((match) => convertToMatchType(match)));
            setActiveBracket(pos); */

            if (races.matches)
                setMatches(races.matches.map((match) => convertToMatchType(match)));
                //setAll({ matches: races.matches.map((match) => convertToMatchType(match)), position: pos ? pos : all.position});
            else
                setMatches([]);
                //setAll({ matches: [], position: pos ? pos : all.position});
            
            //if (!props.isJury)
                //setPosition(pos ? pos : { round: 0, position: 1 });
            console.log('reload', positionLocal.current);
            setStartPositionLocal(positionLocal.current);

            setLoading(false);
        }).catch(() => setLoading(false));
    }, [isPro, params]);

    const changeState = useCallback(() => {
        if (props.isJury)
            new ApiBaseClient().GetAuthedClient().changeRaceState(new ChangeRaceStateRequest({
                raceId: Number(params['raceID']),
                toState: isPro ? RaceState.StreetFinal : RaceState.ProFinal
            }));
        
        setIsPro(!isPro);
    }, [isPro, params, props.isJury]);

    const signalUpdateBracket = useCallback((params: any) => {
        if (/* !props.isJury && */ params.isPro === isPro){
            console.log('je tu refresh', params);
            //setActiveBracket({ round: params.round, position: params.row });
            refresh({ round: params.round, position: params.row });

        }
        /* else if (params.isPro === isPro){            
            refresh(undefined);
        } */
        console.log('nerefreshovat');
        /* if (params.isPro === isPro && !props.isJury) {
                setActiveBracket({ round: params.round, position: params.row });
        }
        else if (params.isPro === isPro) {
            refresh();
        } */
    }, [params, isPro]);

    /* useEffect(() => {

        refresh(activeBracket ?? { round: 0, position: 1 });
    }, [activeBracket, refresh]); */

    useEffect(() => {

        refresh(activeBracket ?? { round: 0, position: 1 });
    }, [isPro, refresh]);
    useEffect(() => {

        // ě();
        let connection = SignalRConnector([{
            hubName: "BracketUpdateState", callback: (p) => {
                signalUpdateBracket(p);
            }
        }, { hubName: "ChangeRaceState", callback: (p) => signalUpdateState(p) }]);
        return () => {
            connection.stop();
        };
    }, [signalUpdateState, signalUpdateBracket]);

    const targetRef = useRef<HTMLDivElement>(null);

    const bind = useLongPress(() => {
        alert('Long pressed!');
      }, 
      {
        onStart: event => console.log('Press started'),
        onFinish: event => console.log('Long press finished'),
        onCancel: event => console.log('Press cancelled'),
        onMove: event => console.log('Detected mouse or touch movement'),
        filterEvents: event => true, // All events can potentially trigger long press (same as 'undefined')
        threshold: 1500, // In milliseconds
        captureEvent: true, // Event won't get cleared after React finish processing it
        cancelOnMovement: 25, // Square side size (in pixels) inside which movement won't cancel long press
        cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
      });
    
    const positionLocal = useRef<number[]>([0,0]);
    const [startPositionLocal, setStartPositionLocal] = useState<number[]>([0,0]);
    
    if (loading)
        return <CircularProgress />
    else if (matches.length > 0)
        return <div style={{ marginLeft: '-160px', marginTop: '-40px', marginRight: '-160px' }}>
            <div ref={targetRef}><SingleEliminationBracket
                onValueChange={(e) => {console.log("pozice:", e);
                    if (!isNaN(e.f) && !isNaN(e.e) && (positionLocal[0] !== e.e || positionLocal[1] !== e.f)) {
                        console.log(positionLocal, e);
                        positionLocal.current = [e.e , e.f, e.a];
                    }
                        
                }}
                theme={GlootTheme}
                matches={matches}                
                /* currentRound={position ? position.round + "" : "0"}
                currentRow={position ? position.position + "" : "1"} */
                startE={startPositionLocal[0]}
                startF={startPositionLocal[1]}
                usePosition={false}                
                //   matchComponent={Match}
                svgWrapper={({ children, ...props }) => (
                    <SVGViewer                       
                        width={finalWidth} height={finalHeight}
                        // tady ta height má vliv na dragovatelnost (když je větší než celkové výška vygenerovaného bracketu, tak nejde drag))

                        background="white"
                        SVGBackground="#FFFFFF"
                        //startAt={positionLocal}
                        {...props}
                        
                    >
                        {children}
                    </SVGViewer>
                )}
                matchComponent={({
                    match,
                    onMatchClick,
                    onPartyClick,
                    onMouseEnter,
                    onMouseLeave,
                    topParty,
                    bottomParty,
                    topWon,
                    bottomWon,
                    topHovered,
                    bottomHovered,
                    topText,
                    bottomText,
                    connectorColor,
                    computedStyles,
                    teamNameFallback,
                    resultFallback,
                }) => (
                    // <div {...bind()}>
                        <a
                        id={match.id!.toString()}
                        onClick={(r) => {
                            console.log(match);
                            // onMatchClick({ match: match, topWon: match.participants[0].isWinner === true, bottomWon: match.participants[1].isWinner === true, event: r });
                        }}
                        style={{
                            // position: 'relative', // Add this line
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            color: match.state === "PLAYED" ? '#000' : (match.state === "RUNNING" ? 'white' : '#000'),

                            minHeight: 'calc(100% - 40px)',
                            border: '2px solid #D82C90',
                            padding: '8px 24px 8px 8px',
                            background: match.state === "PLAYED" ? '#D82C9020' : (match.state === "RUNNING" ? '#D82C90' : 'white')
                        }}
                    >
                        <div style={{ display: props.isJury ? 'flex' : ''}}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: props.isJury ? '20px' : '0px', minWidth: '224px'}}>
                                <div style={{ paddingRight: '12px', alignSelf: 'center' }}><Typography variant="h6" color={match.state === "PLAYED" ? '#D82C90' : (match.state === "RUNNING" ? 'white' : '#D82C90')} fontSize='20px'>{match.tournamentRoundText}</Typography></div>
                                <div style={{ flexGrow: 1 }}>
                                    <div
                                        onMouseEnter={() => onMouseEnter(topParty.id)}
                                        onTouchEnd={() => {
                                            if (props.isJury) {
                                                if (match.state === "SCHEDULED" && props.isJury)
                                                    new ApiBaseClient().GetAuthedClient().updateBracketMatch(new UpdateRaceBracketRequest({ firstWon: true, matchId: Number(match.id), winnerId: Number(topParty.id) }));
                                                onPartyClick(topParty, true);
                                                console.log(topParty, match);
                                            }
                                        }}
                                        onClick={() => {
                                            if (props.isJury) {
                                                if (match.state === "SCHEDULED" && props.isJury)
                                                    new ApiBaseClient().GetAuthedClient().updateBracketMatch(new UpdateRaceBracketRequest({ firstWon: true, matchId: Number(match.id), winnerId: Number(topParty.id) }));
                                                onPartyClick(topParty, true);
                                                console.log(topParty, match);
                                            }
                                        }}
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >

                                        <div >
                                            <div><Typography variant="body1">{topParty.name || teamNameFallback}</Typography></div>
                                            <div><Typography variant="h6" fontSize='14px'>{topParty.resultText ?? resultFallback(topParty)} {/* - {topParty.resultText ?? resultFallback(topParty) */}</Typography></div>
                                        </div>
                                        <div>{topParty.isWinner === true && <CheckOutlined sx={{ color: '#D82C90', paddingTop: '4px' }} />}</div>
                                    </div>
                                    <div
                                        style={{ height: '2px', width: '100%', background: match.state === "PLAYED" ? '#D82C90' : (match.state === "RUNNING" ? 'white' : '#D82C90') }}
                                    />
                                    <div
                                        onMouseEnter={() => onMouseEnter(bottomParty.id)}
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                        onTouchEnd={() => {
                                            if (props.isJury) {
                                                onPartyClick(bottomParty, true); console.log(bottomParty, match);
                                                if (match.state === "SCHEDULED" && props.isJury)
                                                    new ApiBaseClient().GetAuthedClient().updateBracketMatch(new UpdateRaceBracketRequest({ firstWon: false, matchId: Number(match.id), winnerId: Number(bottomParty.id) }));
                                            }
                                        }}
                                        onClick={() => {
                                            if (props.isJury) {
                                                onPartyClick(bottomParty, true); console.log(bottomParty, match);
                                                if (match.state === "SCHEDULED" && props.isJury)
                                                    new ApiBaseClient().GetAuthedClient().updateBracketMatch(new UpdateRaceBracketRequest({ firstWon: false, matchId: Number(match.id), winnerId: Number(bottomParty.id) }));
                                            }
                                        }}
                                    >
                                        <div>
                                            <div><Typography variant="body1">{bottomParty.name || teamNameFallback}</Typography></div>
                                            <div><Typography variant="h6" fontSize='14px'>{bottomParty.resultText ?? resultFallback(topParty)} {/* - {bottomParty.resultText ?? resultFallback(topParty) */}</Typography></div>
                                        </div>
                                        <div>{bottomParty.isWinner === true && <CheckOutlined sx={{ color: '#D82C90', paddingTop: '8px' }} />}</div>
                                    </div>
                                </div>
                            </div>
                            
                            {props.isJury && 
                                <div style={{ }}> 
                                    <div>     
                                        {match.state === "PLAYED" && <div>
                                            <IconButton  sx={{ fontSize: '8px' }} size="small" onClick={() => setUndoMatchId(Number(match.id))} onTouchEnd={() => setUndoMatchId(Number(match.id))}>
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </div>}
                                        <div>
                                            <IconButton  sx={{ fontSize: '8px' }} size="small" onClick={() => 
                                                    sendFocusMatch(Number(match.id))
                                                }
                                                onTouchEnd={() => 
                                                    sendFocusMatch(Number(match.id))
                                                }>
                                                <Visibility fontSize="small" />
                                            </IconButton>
                                        </div>
                                    </div>   
                                </div>
                            }
                        </div>
                    </a>
                    // </div>
                )}
                options={{
                    style: {
                        roundHeader:
                        {
                            roundTextGenerator: (currentRoundNumber, roundsTotalNumber) => {
                                if (currentRoundNumber === roundsTotalNumber) {
                                    return 'FINÁLE';
                                }
                                if (currentRoundNumber === roundsTotalNumber - 1) {
                                    return 'SEMIFINÁLE';
                                }
                                else if (roundsTotalNumber > 4 && currentRoundNumber === roundsTotalNumber - 2) {
                                    return 'ČTVRTFINÁLE';
                                } else
                                    return `${currentRoundNumber}. KOLO`;

                            }
                        },
                        boxHeight: 134
                    }
                }}
                onMatchClick={(match) => console.log(match)}
                onPartyClick={(match, partyWon) => { console.log(match, partyWon) }}
            /></div>
            <div style={{ position: 'fixed', bottom: biggerThan700 ? '8%' : '80px', top: biggerThan700 ? '92%' : '90%', left: biggerThan700 ? '38%' : '16px' }}>
                <Button sx={{ marginRight: biggerThan700 ? '20px' : '4px' }}
                    variant="contained"
                    onClick={changeState}
                >{biggerThan700 ? (isPro ? 'STREET' : 'PRO') : (isPro ? 'ST.' : 'PR.')}</Button>
                {/* {props.isJury && <Button sx={{ marginRight: biggerThan700 ? '20px' : '4px' }}
                    variant="contained"
                    onClick={undo}>{biggerThan700 ? 'VRÁTIT KROK' : 'VRÁTIT'}</Button>} */}
                {props.isJury && <Button sx={{ marginRight: biggerThan700 ? '20px' : '4px' }}
                    variant="contained"
                    onClick={() => {
                        navigate('/eventsadmin');
                    }}>{biggerThan700 ? 'Seznam závodů' : 'Seznam'}</Button>}
                {/* <Button sx={{ marginRight: biggerThan700 ? '20px' : '4px' }}
                    variant="contained"
                    onClick={printPdf}>{biggerThan700 ? 'Image' : 'IMG'}</Button> */}
            </div>
            <UndoBracketDialog open={undoMatchId !== undefined} onClose={() => { setUndoMatchId(undefined) }} onAgree={() => { 
                if (undoMatchId) 
                    undo(undoMatchId); 
            }} />
        </div>
    else return <div>
        <div>Battle ještě nezačal, přijďte později ...</div>
        <div style={{ position: 'fixed', bottom: biggerThan700 ? '8%' : '80px', top: biggerThan700 ? '92%' : '90%', left: biggerThan700 ? '38%' : '16px' }}>
                <Button sx={{ marginRight: biggerThan700 ? '20px' : '4px' }}
                    variant="contained"
                    onClick={changeState}
                >{biggerThan700 ? (isPro ? 'STREET' : 'PRO') : (isPro ? 'ST.' : 'PR.')}</Button>
                {props.isJury && <Button sx={{ marginRight: biggerThan700 ? '20px' : '4px' }}
                    variant="contained"
                    onClick={() => {
                        navigate('/eventsadmin');
                    }}>{biggerThan700 ? 'Seznam závodů' : 'Seznam'}</Button>}
            </div>
    </div>;
};

const GlootTheme = createTheme({
    textColor: { main: '#000000', highlighted: '#07090D', dark: '#3E414D' },
    matchBackground: { wonColor: '#daebf9', lostColor: '#96c6da' },
    score: {
        background: { wonColor: '#87b2c4', lostColor: '#87b2c4' },
        text: { highlightedWonColor: '#7BF59D', highlightedLostColor: '#FB7E94' },
    },
    border: {
        color: '#CED1F2',
        highlightedColor: '#da96c6',
    },
    roundHeader: { backgroundColor: '#da96c6', fontColor: '#000' },
    connectorColor: '#CED1F2',
    connectorColorHighlight: '#da96c6',
    svgBackground: '#FAFAFA',
    roundHeaders: { background: '#D82C90' }

});
// export const simpleSmallBracket = [
//     {
//         id: 19753,
//         nextMatchId: null,
//         tournamentRoundText: "3",
//         startTime: "2021-05-30",
//         state: "SCHEDULED",
//         participants: []
//     },
//     {
//         id: 19754,
//         nextMatchId: 19753,
//         tournamentRoundText: "2",
//         startTime: "2021-05-30",
//         state: "SCHEDULED",
//         participants: [
//             {
//                 id: "14754a1a-932c-4992-8dec-f7f94a339960",
//                 resultText: null,
//                 isWinner: false,
//                 status: null,
//                 name: "CoKe BoYz",
//                 picture: "teamlogos/client_team_default_logo"
//             }
//         ]
//     },
//     {
//         id: 19755,
//         nextMatchId: 19754,
//         tournamentRoundText: "1",
//         startTime: "2021-05-30",
//         state: "SCORE_DONE",
//         participants: [
//             {
//                 id: "14754a1a-932c-4992-8dec-f7f94a339960",
//                 resultText: "Won",
//                 isWinner: true,
//                 status: "PLAYED",
//                 name: "CoKe BoYz",
//                 picture: "teamlogos/client_team_default_logo"
//             },
//             {
//                 id: "d16315d4-7f2d-427b-ae75-63a1ae82c0a8",
//                 resultText: "Lost",
//                 isWinner: false,
//                 status: "PLAYED",
//                 name: "Aids Team",
//                 picture: "teamlogos/client_team_default_logo"
//             }
//         ]
//     },
//     {
//         id: 19756,
//         nextMatchId: 19754,
//         tournamentRoundText: "1",
//         startTime: "2021-05-30",
//         state: "RUNNING",
//         participants: [
//             {
//                 id: "d8b9f00a-0ffa-4527-8316-da701894768e",
//                 resultText: null,
//                 isWinner: false,
//                 status: null,
//                 name: "Art of kill",
//                 picture: "teamlogos/client_team_default_logo"
//             }
//         ]
//     },
//     {
//         id: 19757,
//         nextMatchId: 19753,
//         tournamentRoundText: "2",
//         startTime: "2021-05-30",
//         state: "SCHEDULED",
//         participants: []
//     },
//     {
//         id: 19758,
//         nextMatchId: 19757,
//         tournamentRoundText: "1",
//         startTime: "2021-05-30",
//         state: "SCHEDULED",
//         participants: [
//             {
//                 id: "9397971f-4b2f-44eb-a094-722eb286c59b",
//                 resultText: null,
//                 isWinner: false,
//                 status: null,
//                 name: "Crazy Pepes",
//                 picture: "teamlogos/client_team_default_logo"
//             }
//         ]
//     },
//     {
//         id: 19759,
//         nextMatchId: 19757,
//         tournamentRoundText: "1",
//         startTime: "2021-05-30",
//         state: "SCHEDULED",
//         participants: [
//             {
//                 id: "42fecd89-dc83-4821-80d3-718acb50a30c",
//                 resultText: null,
//                 isWinner: false,
//                 status: null,
//                 name: "BLUEJAYS",
//                 picture: "teamlogos/client_team_default_logo"
//             },
//             {
//                 id: "df01fe2c-18db-4190-9f9e-aa63364128fe",
//                 resultText: null,
//                 isWinner: false,
//                 status: null,
//                 name: "Bosphorus",
//                 picture: "teamlogos/r7zn4gr8eajivapvjyzd"
//             }
//         ]
//     }
// ];
