import { Navigate } from "react-router";
import React from "react";

export interface ProtectedRouteProps {
    redirectPath?: string;
    // open: boolean;
    // options: string[];
    children: any;
}


export default function ProtectedRoute(props: ProtectedRouteProps) {

    const { redirectPath, children } = props;
    // console.log(children);
    let redirect: string = "/";
    if (redirectPath !== undefined)
        redirect = redirectPath!;
    var token = localStorage.getItem('token');
    // console.log(token);
    if (token === null || token === undefined) {


        return <Navigate to={redirect} replace />;
        // })

    }
    else
        return children;


}