import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import React, { useEffect, useState } from 'react'
import SignalRConnector from './SinglaRConnector';

export default function SignalTest() {
    const [messagesQualify, setMessagesQualify] = useState(0);
    const [messagesBracket, setMessagesBracket] = useState(0);
    useEffect(() => {
        console.log(localStorage.getItem('token')!);
        // const connection = new HubConnectionBuilder()
        //     .withUrl(process.env.REACT_APP_API_URL + "/activeRaceHub", {
        //         skipNegotiation: true,
        //         transport: HttpTransportType.WebSockets,
        //         accessTokenFactory: () => localStorage.getItem('token')!
        //     }) // Specify the complete URL of your SignalR hub
        //     .withAutomaticReconnect()

        //     .build();
        // connection.stop().then(() => {

        //     connection.start()
        //         .then(() => console.log('SignalR Connected'))
        //         .catch(err => console.error('SignalR Connection Error: ', err));

        //     connection.on("UpdateNumber", () => {
        //         console.log("UpdateNumber");
        //         setMessagesQualify(prevMessagesBracket => prevMessagesBracket + 1);

        //         //   fetchData(); // Call your function to fetch race results
        //     });
        //     connection.on("UpdateBracket", () => setMessagesBracket(prevMessagesBracket => prevMessagesBracket + 1));

        // });

        var connection = SignalRConnector([{ hubName: "UpdateNumber", callback: () => { setMessagesQualify(prevMessagesBracket => prevMessagesBracket + 1); } }]);
        return () => {
            connection.stop();
        };
    }, []);

    return (
        <div>{messagesBracket} {messagesQualify}</div>
    )

}
