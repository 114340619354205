import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router'
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { CategoryDTO, DeleteCategoryRequest} from '../Api/ApiServerDC';
import { Button, Checkbox, FormControlLabel, IconButton, TextField, Typography } from '@mui/material';
import { Check, DeleteOutline } from '@mui/icons-material';
import CategoryDialog from './CategoryDialog';
import DeleteDialog from '../Shared/DeleteDialog';

export default function RaceCategoriesAdministration() {
    const [raceId, setRaceId] = useState<number>();
    const [categories, setCategories] = useState<CategoryDTO[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<CategoryDTO>();
    const [selectedDeleteCategory, setSelectedDeleteCategory] = useState<CategoryDTO>();
    const params = useParams();
    const navigate = useNavigate();
    /* useEffect(() => {
        reloadData();
    }, []); */

    useEffect(() => {
        reloadData();
    }, [raceId]);

    useEffect(() => {
        if (params['raceID'] !== undefined)
            setRaceId(Number(params['raceID']))
        else setRaceId(undefined)
    }, [params]);

    const reloadData = () => {
        if (params['raceID'] !== undefined && !Number.isNaN(params['raceID'])) {

            new ApiBaseClient().GetAuthedClient().getAllCategoriesForEvent(Number(params['raceID'])).then((categories) => {
                if (categories.categories)
                    setCategories(categories.categories);
                // if (events.races)
                //     setRaces(events.races);
            });
        }
    }
    return (
        <div style={{margin: '0px -120px'}}>
            <div style={{textAlign: 'center'}}><Typography variant='h4' color='#D82C90'>KATEGORIE</Typography></div>
            <div style={{position: 'absolute', left: '4%', top: '6%'}}><Button onClick={() => { navigate('/eventsadmin') }}>Zpět </Button></div>
            
            {categories && <div style={{ display: 'flex'}}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <Typography sx={{ margin:'12px', padding: '12px' }} variant='h5' color='#D82C90'>STREET</Typography>
                        <Button variant='contained' sx={{ margin: '12px', height: '40px', marginTop: '24px' }} onClick={() => { 
                            setSelectedCategory(new CategoryDTO({isPro: false, isStreet: true}));
                        }}><Typography variant='h6'>PŘIDAT</Typography></Button> 
                    </div>
                    {categories.filter(p => !p.isPro).map((cat, i) => {
                        return <div key={i} style={{ display: 'flex', alignItems: 'center', margin:'12px', border: '1px solid #D82C90', padding: '12px', borderRadius: '4px', justifyContent: 'space-between' }}>
                            <div style={{ width: '240px' }}><Typography variant='body1'>{cat.name}{' (' + cat.maximalPoints + ')'}</Typography></div>                            
                            <IconButton sx={{ minWidth: '32px', marginLeft: '6px', marginRight: '6px' }} onClick={() => { setSelectedDeleteCategory(cat) }}><DeleteOutline/></IconButton>
                            {/* <TextField
                                label='číslo'
                                type='number' value={attendee.startNumber} onChange={(e) => {
                                    new ApiBaseClient().GetAuthedClient().updateRaceAttendeesBeforeStart(new UpdateRaceAttendeesBeforeStartRequest({
                                        attendeeId: attendee.id,
                                        startNumber: Number(e.currentTarget.value),
                                        signatureBase64: undefined
                                    })).then(() => reloadData())
                                }}>
                            </TextField> */}
                        </div>;
                        })}
                </div>
                <div style={{ width: '20px'}}>

                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <Typography sx={{ margin:'12px', padding: '12px' }} variant='h5' color='#D82C90'>PRO</Typography>
                        <Button variant='contained' sx={{ margin: '12px', height: '40px', marginTop: '24px' }} onClick={() => { 
                            setSelectedCategory(new CategoryDTO({isPro: true, isStreet: false}));
                        }}><Typography variant='h6'>PŘIDAT</Typography></Button> 
                    </div>
                    {categories.filter(p => p.isPro).map((cat, i) => {
                        return <div key={i} style={{ display: 'flex', alignItems: 'center', margin:'12px', border: '1px solid #D82C90', padding: '12px', borderRadius: '4px', justifyContent: 'space-between' }}>
                            <div style={{ width: '240px' }}><Typography variant='body1'>{cat.name}{' (' + cat.maximalPoints + ')'}</Typography></div>
                            <IconButton sx={{ minWidth: '32px', marginLeft: '6px', marginRight: '6px' }} onClick={() => { setSelectedDeleteCategory(cat) }}><DeleteOutline/></IconButton>
                            {/* <TextField
                                label='číslo'
                                type='number' value={attendee.startNumber} onChange={(e) => {
                                    new ApiBaseClient().GetAuthedClient().updateRaceAttendeesBeforeStart(new UpdateRaceAttendeesBeforeStartRequest({
                                        attendeeId: attendee.id,
                                        startNumber: Number(e.currentTarget.value),
                                        signatureBase64: undefined
                                    })).then(() => reloadData())
                                }}>
                            </TextField> */}
                        </div>;
                        })}
                </div>
            </div>}
            <CategoryDialog isOpen={selectedCategory !== undefined} raceId={raceId} category={selectedCategory} onClose={(refresh) => { setSelectedCategory(undefined); if (refresh) reloadData(); }} />
            <DeleteDialog onDeleteAgree={() => {
                if (selectedDeleteCategory){
                    new ApiBaseClient().GetAuthedClient().deleteCategory(new DeleteCategoryRequest({ categoryId: selectedDeleteCategory.id })).then((res) => { setSelectedDeleteCategory(undefined); if (res.ok) reloadData();});
                }
            }} onClose={() => setSelectedDeleteCategory(undefined)} open={selectedDeleteCategory !== undefined} />
        </div>
    )
}
