import React, { useEffect } from 'react'
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { DeleteRaceRequest, RaceDTO, SetRaceClosedRegistrationRequest } from '../Api/ApiServerDC';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import RaceDetail from './RaceDetail';
import { DeleteOutline, DoDisturb, EditOutlined, Lock, LockOpen } from '@mui/icons-material';
import DeleteDialog from '../Shared/DeleteDialog';

export default function RacesListAdministration() {
    const [races, setRaces] = React.useState<RaceDTO[]>([]);
    const [updatingRace, setUpdatingRace] = React.useState<RaceDTO | null>(null);
    const [deletingRace, setDeletingRace] = React.useState<RaceDTO | null>(undefined);
    const navigate = useNavigate();
    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = () => {
        new ApiBaseClient().GetAuthedClient().eventsForAdmin().then((events) => {
            if (events.races)
                setRaces(events.races);
        });
    };

    const setRegitraionClosed = (raceId: number, close: boolean) => {
        new ApiBaseClient().GetAuthedClient().setRaceClosedRegistration(new SetRaceClosedRegistrationRequest({ matchId: raceId, close: close})).then((res) => {
            if (res.ok) refreshData();
        });
    }

    return (
        <div>
            <Button variant='contained' onClick={() => navigate('/racedetail')}>přidat</Button>
            <div style={{ width: '100%' }}>
                {races.map((x, i) => <div key={i} style={{ width: '100%', alignItems: 'center', fontSize: '20px', border: '1px solid #D82C90', borderRadius: '4px', padding: '16px', marginTop: '16px', minWidth: '700px' }} onDoubleClick={() => setUpdatingRace(x)}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{ marginLeft: '10px' }}><Typography variant='h5'>{x.name}</Typography></div>
                            <div style={{ marginTop: '16px', marginLeft: '8px' }}>{x.dateStart?.toLocaleString('cs')}</div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {x.registrationClosed && <Typography variant='h6' color='error' sx={{ marginRight: '16px'}}>Registrace uzavřena</Typography>}
                                {x.registrationClosed && <IconButton sx={{ minWidth: '32px', marginLeft: '6px', marginRight: '6px' }} onClick={() => { setRegitraionClosed(x.id, !x.registrationClosed) }}>
                                    <LockOpen />
                                </IconButton>}
                                {!x.registrationClosed && <IconButton sx={{ minWidth: '32px', marginLeft: '6px', marginRight: '6px' }} onClick={() => { setRegitraionClosed(x.id, !x.registrationClosed) }}>
                                    <Lock />
                                </IconButton>}
                                <IconButton sx={{ minWidth: '32px', marginLeft: '6px', marginRight: '6px' }} onClick={() => { setUpdatingRace(x); }}><EditOutlined/></IconButton>
                                <IconButton sx={{ minWidth: '32px', marginLeft: '6px', marginRight: '6px' }} onClick={() => { setDeletingRace(x); }}><DeleteOutline/></IconButton>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', gap: 2 }}>

                        <Button sx={{ minWidth: '100px'}} size='small' variant='contained' onClick={() => { navigate('/attendees/' + x.id) }}>Zobrazit účastníky</Button>
                        <Button sx={{ minWidth: '100px'}} size='small' variant='contained' onClick={() => { navigate('/categories/' + x.id) }}>Kategorie</Button>
                        <Button sx={{ minWidth: '100px'}} size='small' variant='contained' onClick={() => { navigate('/racestart/' + x.id) }}>Start závodu</Button>
                        <Button sx={{ minWidth: '100px'}} size='small' variant='contained' onClick={() => { navigate('/qualification/' + x.id) }}>Kvalifikace</Button>                        
                        <Button sx={{ minWidth: '100px'}} size='small' variant='contained' onClick={() => { navigate('/bracket/' + x.id) }}>Pavouk</Button>
                        <Button sx={{ minWidth: '100px'}} size='small' variant='outlined' onClick={() => { navigate('/qualification_public/' + x.id) }}>Kvalifikace veřejná</Button>
                        <Button sx={{ minWidth: '100px'}} size='small' variant='outlined' onClick={() => { navigate('/bracket_public/' + x.id) }}>Pavouk veřejný</Button>
                    </div>

                </div>)}
            </div>
            <DeleteDialog onDeleteAgree={() => {
                if (deletingRace){
                    new ApiBaseClient().GetAuthedClient().deleteRace(new DeleteRaceRequest({ eventId: deletingRace.id })).then((res) => { setDeletingRace(undefined); if (res.ok) refreshData();});
                }
            }} onClose={() => setDeletingRace(undefined)} open={deletingRace !== undefined} />

            <Dialog maxWidth='lg' fullScreen sx={{ minHeight: '300px' }} onClose={() => setUpdatingRace(null)} open={updatingRace !== null}>
                <DialogTitle>
                    <div style={{ background: '#F5F5F5', borderBottom: '1px solid #C3C3C3', margin: '-16px -24px 0px -24px', height: '56px' }}>
                        <div style={{ float: 'left', display: 'flex' }} >
                            <Typography variant='h1' fontWeight='bold' sx={{ marginLeft: '12px' }} lineHeight={'48px'} fontSize={20}>UPRAVIT ZÁVOD</Typography>

                        </div>
                        <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>

                            {/* {biggerThan600 && task!.date && <div>
            <Typography variant='body1' sx={{ marginRight: '12px' }} fontSize={11}>{(task.id! > 0) ? t('Created') + " " + task!.date!.toLocaleString() : ""} </Typography>
        </div>} */}
                            {/* <div style={{ marginTop: '-14px' }}> */}

                            {/* {showCloseText && <Typography variant='h1' color='primary' lineHeight={'42px'} fontSize={16}>ZAVŘÍT</Typography>} */}
                            <Button onClick={() => { setUpdatingRace(null) }} >
                                x
                            </Button>
                            {/* </div> */}
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ background: 'white' }}>
                    <RaceDetail inputRace={updatingRace !== null ? updatingRace : undefined} />
                </DialogContent>
            </Dialog>

        </div>
    )
}
