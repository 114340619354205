import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";

export default function SignalRConnector(connectors: { hubName: string, callback: (params:any[]) => void }[]) {
    const connection = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_API_URL + "/activeRaceHub", {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => localStorage.getItem('token')!
        }) // Specify the complete URL of your SignalR hub
        .withAutomaticReconnect()

        .build();
    connection.stop().then(() => {

        connection.start()
            .then(() => console.log('SignalR Connected'))
            .catch(err => console.error('SignalR Connection Error: ', err));
        for (let connector of connectors)
            connection.on(connector.hubName, (params) => {
                connector.callback(params);
                // console.log("UpdateNumber");
                // setMessagesQualify(prevMessagesBracket => prevMessagesBracket + 1);

                //   fetchData(); // Call your function to fetch race results
            });
        // connection.on("UpdateBracket", () => setMessagesBracket(prevMessagesBracket => prevMessagesBracket + 1));

    });
    return connection;
}