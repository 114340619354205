import { Button, Dialog, DialogContent, TextField } from '@mui/material'
import React, { useState } from 'react'
interface Props {
    isOpen: boolean;
    onClose: (args: { street: number, pro: number } | null) => void;
}

export default function QualificationEndDialog(props: Props) {
    const [streetNumber, setStreetNumber] = useState(0);
    const [proNumber, setProNumber] = useState(0);
    const { isOpen, onClose } = props;
    return (
        <Dialog maxWidth='lg' fullScreen sx={{ minHeight: '300px' }} onClose={() => onClose(null)} open={isOpen}>
            <DialogContent sx={{ background: 'white' }}>
                <div>
                    <TextField sx={{ margin: '4px ' }} label='STREET' type='number' value={streetNumber} onChange={(e) => {
                        setStreetNumber(Number(e.currentTarget.value));
                    }}></TextField>
                    <TextField sx={{ margin: '4px ' }} label='PRO' type='number' value={proNumber} onChange={(e) => {
                        setProNumber(Number(e.currentTarget.value));
                    }}></TextField>
                    <Button onClick={() => onClose({ pro: proNumber, street: streetNumber })}>OK</Button>
                </div>
                <div style={{ display: 'flex', marginTop: '16px', marginLeft: '8px' }}>
                    <div style={{ minWidth: '240px' }}>
                        <div onClick={() => {
                            setStreetNumber(4);
                        }} style={{ border: '1px solid lightgray', borderRadius: '6px', background: '#F2F2F2', width: '200px', margin: '2px', cursor: 'pointer' }}>
                            <Button
                            >4 závodníci</Button>
                        </div>
                        <div onClick={() => {
                            setStreetNumber(8);
                        }} style={{ border: '1px solid lightgray', borderRadius: '6px', background: '#F2F2F2', width: '200px', margin: '2px', cursor: 'pointer' }}>
                            <Button
                            >8 závodníků</Button>
                        </div>
                        <div onClick={() => {
                            setStreetNumber(16);
                        }} style={{ border: '1px solid lightgray', borderRadius: '6px', background: '#F2F2F2', width: '200px', margin: '2px', cursor: 'pointer' }}>
                            <Button
                            >16 závodníků</Button>
                        </div>
                        <div onClick={() => {
                            setStreetNumber(32);
                        }} style={{ border: '1px solid lightgray', borderRadius: '6px', background: '#F2F2F2', width: '200px', margin: '2px', cursor: 'pointer' }}>
                            <Button
                            >32 závodníků</Button>
                        </div>
                    </div>
                    <div>
                        <div onClick={() => {
                            setProNumber(4);
                        }} style={{ border: '1px solid lightgray', borderRadius: '6px', background: '#F2F2F2', width: '200px', margin: '2px', cursor: 'pointer' }}>
                            <Button
                            >4 závodníci</Button>
                        </div>
                        <div onClick={() => {
                            setProNumber(8);
                        }} style={{ border: '1px solid lightgray', borderRadius: '6px', background: '#F2F2F2', width: '200px', margin: '2px', cursor: 'pointer' }}>
                            <Button
                            >8 závodníků</Button>
                        </div>
                        <div onClick={() => {
                            setProNumber(16);
                        }} style={{ border: '1px solid lightgray', borderRadius: '6px', background: '#F2F2F2', width: '200px', margin: '2px', cursor: 'pointer' }}>
                            <Button
                            >16 závodníků</Button>
                        </div>
                        <div onClick={() => {
                            setProNumber(32);
                        }} style={{ border: '1px solid lightgray', borderRadius: '6px', background: '#F2F2F2', width: '200px', margin: '2px', cursor: 'pointer' }}>
                            <Button
                            >32 závodníků</Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
