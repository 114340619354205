import React, { useEffect, useRef } from 'react'
import { ApiBaseClient } from '../Api/ApiBaseClient';
import { useNavigate, useParams } from 'react-router';
import { DeleteAttendeeRequest, RaceAttendeeDTO } from '../Api/ApiServerDC';
import { Alert, Button, Snackbar, Typography } from '@mui/material';

export default function RaceAttendeesPublic() {
    const [attendees, setAttendees] = React.useState<RaceAttendeeDTO[]>([]);
    const [deleteErrorOpen, setDeleteErrorOpen] = React.useState(false);
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = () => {
        if (params && params["id"])
            new ApiBaseClient().GetClientToAuth().raceAttendeesPublic(Number(params["id"])).then((res) => {
                if (res && res.raceAttendees)
                    setAttendees(res.raceAttendees);
            });
    };

    const getStreetProText = (isStreet: boolean, isPro: boolean) => {
        if (isStreet && isPro) return 'Street & Pro';
        if (isStreet) return 'Street';
        if (isPro) return 'Pro';
        return '';
    }
    
    const tableRef = useRef(null);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', margin: '0px -140px' }}>
            <div><Button onClick={() => { navigate('/events') }}>Zpět </Button></div>
            <div style={{textAlign: 'center'}}>
                <Typography variant='h4' color='#D82C90'>SEZNAM ÚČASTNÍKŮ</Typography></div>
                <table id="my-table" ref={tableRef} style={{ width: '100%', overflowX: 'auto', minWidth: '1300px', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid #D82C90', padding: '8px' }}>Start. č.</th>
                            <th style={{ border: '1px solid #D82C90', padding: '8px' }}>JMÉNO</th>
                            <th style={{ border: '1px solid #D82C90', padding: '8px' }}>PŘÍJMENÍ</th>
                            <th style={{ border: '1px solid #D82C90', padding: '8px' }}>KATEGORIE</th>
                            <th style={{ border: '1px solid #D82C90', padding: '8px' }}>AUTO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendees.map(x => (
                        <tr key={x.id}>
                            <td style={{ border: '1px solid #D82C90', padding: '8px' }}>{x.startNumber}</td>
                            <td style={{ border: '1px solid #D82C90', padding: '8px' }}>{x.firstName}</td>
                            <td style={{ border: '1px solid #D82C90', padding: '8px' }}>{x.lastName}</td>
                            <td style={{ border: '1px solid #D82C90', padding: '8px' }}>{getStreetProText(x.streetCategory, x.proCategory)}</td>
                            <td style={{ border: '1px solid #D82C90', padding: '8px' }}>{x.car}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
    )
}
